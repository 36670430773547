<template>
  <section>
    <Navbar02 />
    <section>
      <!-- Start Page Title Section -->
      <div class="page-title-area contactbg">
        <div class="d-table">
          <div class="d-table-cell">
            <div class="container">
              <div class="page-title-content">
                <h2>Contact</h2>
                <ul>
                  <li><a href="/">Home</a></li>
                  <li>Contact</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Page Title Section -->
    </section>

    <!-- Start Contact Section -->
    <div class="contact-section section-padding">
      <div class="container">
        <div class="section-title">
          <h6 class="sub-title">Let's Talk</h6>
          <h2>Contact Us</h2>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-10 offset-lg-1">
            <div class="contact-form">
              <p class="form-message"></p>

              <form>
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <input
                        type="text"
                        id="name"
                        class="form-control"
                        required
                        placeholder="Your Name"
                        v-model="state.vu_name"
                        v-bind:class="{
                          invaliddata: v$.vu_name.$error == true,
                        }"
                      />
                      <span v-if="v$.vu_name.$error">
                        {{ v$.vu_name.$errors[0].$message }}
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <input
                        type="email"
                        id="email"
                        class="form-control"
                        required
                        placeholder="Your Email"
                        v-model="state.vu_email"
                        v-bind:class="{
                          invaliddata: v$.vu_email.$error == true,
                        }"
                      />
                      <span v-if="v$.vu_email.$error">
                        {{ v$.vu_email.$errors[0].$message }}
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <input
                        type="tel"
                        id="phone"
                        required
                        class="form-control"
                        placeholder="Your Phone"
                        v-model="state.vu_phone"
                        v-bind:class="{
                          invaliddata: v$.vu_phone.$error == true,
                        }"
                      />
                      <span v-if="v$.vu_phone.$error">
                        {{ v$.vu_phone.$errors[0].$message }}
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <input
                        type="text"
                        id="subject"
                        class="form-control"
                        required
                        placeholder="Your Subject"
                        v-model="state.vu_subject"
                        v-bind:class="{
                          invaliddata: v$.vu_subject.$error == true,
                        }"
                      />
                      <span v-if="v$.vu_subject.$error">
                        {{ v$.vu_subject.$errors[0].$message }}
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <textarea
                        class="form-control"
                        id="message"
                        cols="30"
                        rows="6"
                        required
                        placeholder="Your Message"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <button
                      class="default-btn submit-btn"
                      @click="submitForm"
                      type="button"
                    >
                      Send Message <span></span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Contact Section -->
    <!-- Start Contact Info Section -->
    <!-- <section class="contact-info-wrapper bg-grey">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title">
              <h6 class="sub-title">Find Us</h6>
              <h2>Contact Info</h2>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="contact-info-content">
              <h5>USA Headquarter</h5>
              <p>
                304 NW St Homestead, Florida, Melrose Street, Water Mill, 76B
                Overlook Drive Chester, PA 19013, Flemingsburg USA.
              </p>
              <a href="tel:12345678">080 707 555-321</a>
              <a href="mailto:demo@example.com">demo@example.com</a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="contact-info-content">
              <h5>New York Office</h5>
              <p>
                1540 Pecks Ridge Tilton Rd Flemingsburg, Kentucky(KY), 4104188
                Fulton Street Blackwood, NJ 08012, London.
              </p>
              <a href="tel:12345678">080 707 555-321</a>
              <a href="mailto:demo@example.com">demo@example.com</a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="contact-info-content">
              <h5>Panama Office</h5>
              <p>
                103 Richard Ave Ashville, Ohio, Water Mill,3468 16th Hwy
                Pangburn, Arkansas(AR), Charolais Ashville, Virginia, Panama.
              </p>
              <a href="tel:12345678">080 707 555-321</a>
              <a href="mailto:demo@example.com">demo@example.com</a>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- End Contact Info Section -->

    <!-- Start Map Section -->
    <!-- <div class="map-area">
      <div class="map-content">
        <div class="map-canvas" id="contact-map"></div>
      </div>
    </div> -->
    <!-- End Map Section -->
    <Footer />
  </section>
</template>

<script>
//import { ref } from "vue";
import {Email}  from "@/smtp/smtp.js"
import Navbar02 from "../components/Navbar02.vue";
import Footer from "../components/Footer.vue";
import useVuelidate from "@vuelidate/core";
import { reactive, computed } from "vue";
import { required, minLength, email } from "@vuelidate/validators";
import router from "@/router";
export default {
  components: {
    Navbar02,
    Footer,
  },

  // setup() {
  //   const icons = ref([
  //     { name: "alternate_email", text: "by email" },
  //     { name: "local_phone", text: "by phone" },
  //     { name: "local_post_office", text: "by post" },
  //     { name: "local_fire_department", text: "by smoke signal" },
  //   ]);

  //   return { icons };
  // },
  setup() {
    const state = reactive({
      vu_name: "",
      vu_email: "",
      vu_phone: "",
      vu_subject: "",
    });

    const rules = computed(() => {
      return {
        vu_name: { required, minLength: minLength(3) },
        vu_email: { required, email },
        vu_phone: { required },
        vu_subject: { required },
      };
    });

    const v$ = useVuelidate(rules, state);
    return {
      state,
      v$,
    };
  },
  methods: {
    submitForm() {
      try {
        console.log("Hi");
        this.v$.$validate();

        if (!this.v$.$error) {
          this.parentCloseModal();
        }
      } catch (error) {
        console.log("Donetttttt");
        console.log(error);
        alert("form failed validation");
      }
    },
    parentCloseModal() {
      window.scroll(0, 0);
      router.push("/");
    },
  },
};
</script>

<style>
.contactbg {
  background: url("../../public/img/contactus-bg.jpg") no-repeat bottom center;
  background-size: contain;
  background-color: #820f87;
}
.contact ul {
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  max-width: 400px;
  margin: 60px auto;
}
.contact li {
  list-style-type: none;
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  line-height: 1.5em;
}
.form-group span {
  color: red;
}
</style>
