<template>
  <section>
    <!-- Start Testimonial Section -->
    <section class="testimonial-section pt-100 pb-50">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title">
              <h6 class="sub-title">Client Testimonial</h6>
              <h2>Glimpse into What Others Have to Say</h2>
            </div>
          </div>
          <div class="col-lg-12 col-md-12">
            <!-- <carousel
              :items-to-show="3"
              :itemsToScroll="1"
              :autoplay="0"
              :wrapAround="false"
              :transition="300"
              :settings="settings"
              :breakpoints="breakpoints"
            > -->
            <carousel :settings="settings" :breakpoints="breakpoints">
              <slide v-for="slide in slides" :key="slide">
                <!-- testimonials item -->

                <div class="single-testimonial">
                  <div class="rating-box">
                    <ul>
                      <li><i class="fa fa-star"></i></li>
                      <li><i class="fa fa-star"></i></li>
                      <li><i class="fa fa-star"></i></li>
                      <li><i class="fa fa-star"></i></li>
                      <li><i class="fa fa-star"></i></li>
                    </ul>
                  </div>
                  <div class="testimonial-content">
                    <p>
                      {{ slide.text }}
                    </p>
                  </div>
                  <div class="avatar">
                    <img :src="slide.imgpath" alt="testimonial images" />
                  </div>
                  <div class="testimonial-bio">
                    <div class="bio-info">
                      <h3>{{ slide.testiname }}</h3>
                      <span>{{ slide.country }}</span>
                    </div>
                  </div>
                </div>

                <!-- testimonials item -->
                <!-- {{ slide }} -->
              </slide>

              <template #addons>
                <!-- <navigation /> -->
                <!--  <pagination /> -->
              </template>
            </carousel>
            <div class="testimonial-slider owl-carousel owl-theme">
              <!-- testimonials item -->

              <!-- <div class="single-testimonial">
                <div class="rating-box">
                  <ul>
                    <li><i class="fa fa-star"></i></li>
                    <li><i class="fa fa-star"></i></li>
                    <li><i class="fa fa-star"></i></li>
                    <li><i class="fa fa-star"></i></li>
                    <li><i class="fa fa-star"></i></li>
                  </ul>
                </div>
                <div class="testimonial-content">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. enim ad minim veniam, quis nostrud exercitation.
                  </p>
                </div>
                <div class="avatar">
                  <img
                    src="assets/img/client/testimonial-1.jpg"
                    alt="testimonial images"
                  />
                </div>
                <div class="testimonial-bio">
                  <div class="bio-info">
                    <h3>Saabir al-Obeid</h3>
                    <span>Turkey</span>
                  </div>
                </div>
              </div> -->
            </div>

            <!-- <dotlottie-player
              id="owais"
              src="https://lottie.host/197dab34-b1af-4752-a63c-ec5825017d5f/mfZT6go5fz.json"
              background="transparent"
              speed="1"
              style="width: 300px; height: 300px"
              loop
              autoplay
            ></dotlottie-player> -->
            <!-- <dotlottie-player              
              src="./img/lottie/banner.json"
              background="transparent"
              speed="1"
              style="width: 600px; height: 600px"
              loop
              autoplay
            ></dotlottie-player> -->
          </div>
        </div>
      </div>
    </section>
    <!-- End Testimonial Section -->
  </section>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
// import "location" from "../../src/assets/location.json"
//import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { Carousel, Slide } from "vue3-carousel";
//import Lottie from "lottie-web";
export default {
  name: "Testimonials",
  components: {
    Carousel,
    Slide,
    // Pagination,
    // Navigation,
  },

  setup() {
    return {
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
  data() {
    return {
      slides: [
        {
          id: 1,
          text: "The privacy-centric design is commendable. It always gives me control over who sees my location, striking the right balance between connectivity and personal space. Great initiative.",
          imgpath: "img/client/testimonial-1.jpg",
          testiname: "Eric William",
          country: "Germany",
        },
        {
          id: 2,
          text: "As a parent, having an app that can help track my children's location in real-time provides unmatched peace of mind. This app is a must-have for every parent out there! I highly recommend this fantastic app.",
          imgpath: "img/client/testimonial-2.jpg",
          testiname: "Emily Merchant",
          country: "United States",
        },
        {
          id: 3,
          text: "I love the in-app chatting feature! It keeps our family conversations in one place, making it so much easier to plan and share updates. I also love the app’s group-sharing feature, which makes it exceptional.",
          imgpath: "img/client/testimonial-3.jpg",
          testiname: "David Jeremy",
          country: "Turkey",
        },
        {
          id: 4,
          text: "I've tried different tracking apps, but TrackPal stands out for its simplicity and robust features. The in-app chatting and group travel functions are excellent, making it an all-in-one solution for my needs. Highly recommended.",
          imgpath: "img/client/testimonials-4.jpg",
          testiname: "Sarah Luis",
          country: "UK",
        },
        {
          id: 5,
          text: "TrackPal has genuinely revolutionized the way I keep tabs on my family. The real-time tracking is incredibly accurate, and the app's user-friendly interface makes it incredible. Highly recommended for anyone seeking a reliable location-tracking solution!",
          imgpath: "img/client/testimonials-5.jpg",
          testiname: "Joshua Jonathan",
          country: "India",
        },
        // Add more items as needed
      ],
    };
  },
};
</script>

<style>
.about {
  max-width: 600px;
  margin: 20px auto;
}
.testimonial-section {
  background: #f7f7f7;
}
.single-testimonial {
  margin: 0 15px;
}
/* .carousel__viewport ol li {
  width: 100%;
} */

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media screen and (min-device-width: 768px) {
  .about-btn-box {
    display: inline-block;
    margin-top: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .about-btn-box {
    display: inline-block;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
</style>
