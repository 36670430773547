
export const ENDPOINTS = {

    // user endpoints

    USER_LOGIN: `/firebase-login`,
    VERIFY: `/verify`,
    USER_DELETE: `/delete-acc`,
    //

    UPDATE_USER_PROFILE: `/update-profile`
}