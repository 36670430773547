<template>
  <section>
    <Navbar02 />
    <section>
      <!-- Start Page Title Section -->
      <div class="page-title-area">
        <div class="d-table">
          <div class="d-table-cell">
            <div class="container">
              <div class="page-title-content">
                <h2>User Login</h2>
                <ul>
                  <li><a href="#">Home</a></li>
                  <li>User Login</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Page Title Section -->
    </section>
    <section>
      <div class="contact-section section-padding">
        <div class="container">
          <div class="section-title">
            <h6 class="sub-title">User's Login</h6>
            <h2>Login Details</h2>
          </div>

          <section>
            <div class="container-fluid h-custom">
              <div
                class="row d-flex justify-content-center align-items-center h-100"
              >
                <div class="col-md-9 col-lg-6 col-xl-5">
                  <!-- <img
                    src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
                    class="img-fluid"
                    alt="Sample image"
                  /> -->
                  <div
                    ref="dotlottieplayer"
                    src="./img/lottie/login.json"
                    background="transparent"
                    speed="1"
                    style="width: 600px; height: 600px"
                    loop
                    autoplay
                  ></div>
                </div>
                <div class="col-md-8 col-lg-6 col-xl-4">
                  <div class="card card-profile shadow infoboxx user-profile">
                    <!-- <h2 class="fw-bold mb-2 text-uppercase">Login</h2> -->
                    <p class="text-black-50">
                      Please enter your login and password!
                    </p>
                    <form @submit.prevent="submitForm">
                      <!-- Email input -->
                      <div class="form-outline mb-4">
                        <label class="form-label" for="form2Example1"
                          >Email address</label
                        >
                        <input
                          type="email"
                          id="form2Example1"
                          class="form-control"
                          v-model="state.vu_email"
                          v-bind:class="{
                            invaliddata: v$.vu_email.$error == true,
                          }"
                        />
                        <span v-if="v$.vu_email.$error">
                          {{ v$.vu_email.$errors[0].$message }}
                        </span>
                      </div>

                      <!-- Password input -->
                      <div class="form-outline mb-4">
                        <label class="form-label" for="form2Example2"
                          >Password</label
                        >
                        <input
                          type="password"
                          id="form2Example2"
                          class="form-control"
                          v-model="state.vu_password"
                          v-bind:class="{
                            invaliddata: v$.vu_password.$error == true,
                          }"
                        />
                        <span v-if="v$.vu_password.$error">
                          {{ v$.vu_password.$errors[0].$message }}
                        </span>
                      </div>

                      <!-- 2 column grid layout for inline styling -->
                      <div class="row mb-4">
                        <div class="col d-flex justify-content-center">
                          <!-- Checkbox -->
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="form2Example31"
                              checked
                            />
                            <label
                              class="form-check-label"
                              for="form2Example31"
                            >
                              Remember me
                            </label>
                          </div>
                        </div>

                        <div class="col">
                          <!-- Simple link -->
                          <a href="#!" @click="gotoForgetPassword()"
                            >Forgot password?</a
                          >
                        </div>
                      </div>

                      <!-- Submit button -->
                      <button
                        type="button"
                        class="btn btn-primaryy btn-block mb-4"
                        @click="submitForm"
                      >
                        Login Now
                      </button>

                      <!-- Register buttons -->
                      <!-- <div class="text-center">
                        <p>Not a member? <a href="#!">Register</a></p>
                        <p>or sign up with:</p>
                        <button
                          type="button"
                          class="btn btn-link btn-floating mx-1"
                        >
                          <i class="fab fa-facebook-f"></i>
                        </button>

                        <button
                          type="button"
                          class="btn btn-link btn-floating mx-1"
                        >
                          <i class="fab fa-google"></i>
                        </button>

                        <button
                          type="button"
                          class="btn btn-link btn-floating mx-1"
                        >
                          <i class="fab fa-twitter"></i>
                        </button>

                        <button
                          type="button"
                          class="btn btn-link btn-floating mx-1"
                        >
                          <i class="fab fa-github"></i>
                        </button>
                      </div> -->
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
    <!-- <section ref="triggerSection">
      <CounterArea :trigerVal="trigerVal" v-if="trigshow" />
    </section>
    <FAQ /> -->
    <Footer />
  </section>
</template>

<script>
import Navbar02 from "../components/Navbar02.vue";
import Footer from "../components/Footer.vue";
import useVuelidate from "@vuelidate/core";
import { reactive, computed } from "vue";
import { required, minLength, email } from "@vuelidate/validators";
import router from "@/router";
import lottie from "lottie-web";
// import { useStore } from "vuex";
// import { mapActions } from "vuex";
//import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
export default {
  name: "UserLogin",
  components: {
    Navbar02,
    Footer,
  },

  setup() {
    const state = reactive({
      vu_email: "",
      vu_password: "",
    });

    const rules = computed(() => {
      return {
        vu_email: { required, email },
        vu_password: { required, minLength: minLength(3) },
      };
    });

    const v$ = useVuelidate(rules, state);
    return {
      state,
      v$,
    };
  },

  data() {
    return {};
  },
  mounted() {
    // window.addEventListener("scroll", this.handleScroll);

    const animationData = require("../../public/img/lottie/login.json"); // Replace with your animation data file
    const options = {
      container: this.$refs.dotlottieplayer,
      animationData: animationData,
      loop: true,
      autoplay: true,
    };
    this.lottieInstance = lottie.loadAnimation(options);
  },
  beforeUnmount() {
    // Destroy the Lottie animation instance to clean up resources
    if (this.lottieInstance) {
      this.lottieInstance.destroy();
    }
  },

  methods: {
    //...mapActions(["login"]),
    submitForm() {
      try {
        this.v$.$validate();

        if (!this.v$.$error) {
          this.parentCloseModal();
        }
      } catch (error) {
        console.log("Donetttttt");
        console.log(error);
        alert("form failed validation");
      }
    },

    async parentCloseModal() {
      // const store = useStore();
      console.log("ok done now");

      // store.dispatch("Login", {
      //   email: this.vu_email,
      //   password: this.vu_password,
      // });
      const userObj = {
        email: this.state.vu_email,
        password: this.state.vu_password,
      };
      // this.$toast.success("Hello, this is a success toast!");
      this.$store.dispatch("login", userObj);

      // await this.login(userObj);
    },

    // login() {

    // },

    // async logIn() {
    //   try {
    //     // this.btnDissable = true;
    //     // this.success_message = true;
    //     // this.error_message = "";
    //     const auth = getAuth();
    //     const firebase_response = await signInWithEmailAndPassword(
    //       auth,
    //       this.vu_email,
    //       this.vu_password
    //     );
    //     console.log("fb response : ", firebase_response);
    //   } catch {
    //     console.log("Done");
    //   }
    // },
    gotoForgetPassword() {
      window.scroll(0, 0);
      router.push("/forget-password");
    },
  },
};
</script>

<style scoped>
/* .contact ul {
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  max-width: 400px;
  margin: 60px auto;
}
.contact li {
  list-style-type: none;
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  line-height: 1.5em;
} */
.card-profile-image {
  overflow: hidden;
  margin-top: 45px;
  margin-bottom: 15px;
}
.card-profile-image img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin: auto;
  display: table;
}
.infoboxx {
  padding: 30px;
}
.userInfo {
  position: relative;
  margin-top: -15px;
}
.userInfo .lotiibox {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999;
}
.userInfo .section-title {
  margin-bottom: 0;
  text-align: left;
}
.infolabel {
  font-weight: 700;
  color: #000;
  text-align: right;
  font-family: "dosis";
}
.infodes {
  font-weight: 400;
  color: #aa1d88;
  font-size: 1rem;
}
.default-btn-two {
  margin-top: 0;
}
.page-title-content ul {
  margin: auto;
  margin-top: 12px;
  margin-bottom: 0;
  display: table;
  width: auto;
  padding: 0;
}
.form-control:focus {
  border-color: #b61e84;
  box-shadow: 0 0 0 0.25rem rgba(253, 13, 173, 0.25);
}
.form-check-input:checked {
  background-color: #b61e84;
  border-color: #b61e84;
  box-shadow: 0 0 0 0.25rem rgba(253, 13, 173, 0.25);
}
.user-profile h6.text-primary {
  color: #b61e84 !important;
}
.user-profile .form-group {
  margin-bottom: 15px;
}
.user-profile .form-group label {
  color: #e52579;
}
.user-profile .btn-primaryy {
  background: #b61e84;
  color: #fff;
  padding-left: 40px;
  padding-right: 40px;
}
.user-profile .btn-primaryy:hover {
  background: #f32879;
}
.user-profile .form-group span {
  color: red;
}

/* .modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
} */

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.v-toast__text {
  color: #fff !important;
}

.v-toast__item .v-toast__text {
  padding: 1.5em 1em;
  color: #fff;
}
.form-outline span {
  color: red;
}
</style>
