import API from "../config/api.js"
import { ENDPOINTS } from "../config/variables.js";
import { createStore } from 'vuex'
//import { createStore } from 'vuex';
import { auth } from '@/firebase'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { signOut } from 'firebase/auth'
import router from '@/router'
import { toast } from 'vue3-toastify';

const store = createStore({
    state: {
        isLoading: false,
        currentUser: {},
        apiToken: "",
        btnText: 'Login',
        userProfile: {}

    },
    getters: {
        getCurrentUser: (state) => state.currentUser,
        getuserProfile: (state) => state.userProfile,
        getUserToken: (state) => state.apiToken,
        isLoading: (state) => state.isLoading,
    },
    actions: {
        async login({ commit }, details) {

            console.log(details)
            // const { email, password } = details;
            try {
                let resp = await signInWithEmailAndPassword(
                    auth,
                    details.email,
                    details.password
                )
                let respToken = resp.user?.accessToken;

                console.log(resp, "New Status")

                let respp = await API.post(ENDPOINTS.USER_LOGIN, { token: respToken });

                // commit("setUserLogin", response.data);
                console.log(auth.currentUser, "Matched Data")
                console.log(respp.data, "New LOGIN DATA")
                console.log(respp.status, "Status")

                window.scroll(0, 0)
                router.push({ name: "UserProfile" })
                setTimeout(() => {
                    toast.success(respp.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        timeout: 5000,
                        duration: 5000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: false,
                        closeButton: true,
                        icon: true,
                        rtl: false
                    });
                }, 1000);
                commit('SET_USER', respp.data)



                // setTimeout(() => {
                //     // Redirect code here
                //     // e.g., this.$router.push('/new-page');


                // }, 300);
                //  toast.success("Success Notification !", {
                //         position: toast.POSITION.TOP_RIGHT,
                //      timeout: 5000,
                //         duration:5000,
                //         closeOnClick: true,
                //         pauseOnFocusLoss: true,
                //         pauseOnHover: true,
                //         draggable: true,
                //         draggablePercent: 0.6,
                //         showCloseButtonOnHover: false,
                //         hideProgressBar: true,
                //         closeButton: true,
                //         icon: true,
                //         rtl: false
                //     });

                // this.$toast("I'm a toast!", {
                //     position: "top-right",
                //     timeout: 5000,
                //     closeOnClick: true,
                //     pauseOnFocusLoss: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     draggablePercent: 0.6,
                //     showCloseButtonOnHover: false,
                //     hideProgressBar: true,
                //     closeButton: "button",
                //     icon: true,
                //     rtl: false
                // });

                // Force dismiss specific toast


                //Dismiss all opened toast immediately
                //return respp.data                   







            } catch (error) {
                let resperror;
                let error_message = error?.message ?? error;


                console.log(error_message, "Khan Imran")
                if (error_message === 'Firebase: Error (auth/user-not-found).') {
                    resperror = "User Not Found";
                    console.log("User Not Found")
                }

                else if (error_message === 'Firebase: Error (auth/network-request-failed).') {
                    resperror = "Network Request Failed";
                    console.log("Network Request Failed")
                }
                else if (error_message === 'Firebase: Error (auth/wrong-password).') {
                    resperror = "Wrong Password";
                    console.log("Wrong Password")
                }
                else if (error_message === 'Request failed with status code 422') {
                    resperror = "Something Went Wrong!";
                    console.log("Something Went Wrong!")
                }
                if (resperror) {
                    toast.error(resperror, {
                        position: toast.POSITION.TOP_RIGHT,
                        timeout: 5000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: false,
                        className: 'Toastify__toast-theme--colored.Toastify__toast--error',

                        closeButton: true,
                        icon: true,
                        rtl: false,
                        autoClose: true,
                    });
                }
                // if(error.data.error)



                // /./return error.response;

                //console.log(error, "Imran")
                // switch (error.code)
                // {
                //     case 'auth/user-not-found':
                //         alert('User not found')
                //         break
                //     case 'auth/wrong-password':
                //         alert("Wrong Password")
                //         break
                //     default:alert(error.message)
                // }
                // return
                // commit("SET_USER", auth.currentUser)
                // window.scroll(0, 0)
                //router.push('/')
            }
            // commit("SET_USER", auth.currentUser)
            //      router.push('/')
            //router.push('/user-profile')
        },
        // async logout({ commit }) {
        //     //
        // }

        async logout({ commit }) {

            await signOut(auth);
            toast.success("Successuffly Logout !", {
                position: toast.POSITION.TOP_RIGHT,
                timeout: 5000,
                duration: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: true,
                icon: true,
                rtl: false
            });
            router.push("/user-login");
            commit('CLEAR_USER')
        },

        // fetchUser({ commit },user) {
        //     console.log("User Now", user)
        //     auth.onAuthStateChanged(async user => {
        //         if (user == null) {
        //             commit('CLEAR_USER')
        //         } else {
        //             commit('SET_USER', user)

        //             if (router.isReady() && router.currentRoute.value.path === '/user_login') {
        //                 router.puch('/user-profile')
        //             }
        //         }

        //     })
        // }

        updateUserProfileAction: async ({ commit }, data) => {
            console.log("i am in action")
            let response = await API.post(ENDPOINTS.UPDATE_USER_PROFILE, data);
            console.log(response, "Updated User Detail");
            if (response.status == 200) {
                commit("updateUserProfileMutation", response.data);
                return response;
            } else {
                return response;
            }
        },
        setlogout: ({ commit }) => {
            commit("SET_USERr")
        },
        setPassword: ({ commit }, dataEmail) => {
            let resppp = auth.sendPasswordResetEmail(dataEmail);

            console.log(resppp, "zafar Ali Butto", commit)

        },
        verify: async ({ commit }) => {
            try {
                let response = await API.get(ENDPOINTS.VERIFY);
                commit('verifyUserMutate', response.data)
                return true;
            } catch (error) {
                return error.response
            }
        },
        deleteUser: async ({ commit }) => {
            try {
                console.log("Account Delete Function")
                let delresponse = await API.delete(ENDPOINTS.USER_DELETE)
                console.log(delresponse, "account delete")
                commit('CLEAR_USER');
                return delresponse;
            } catch (error) {
                console.log(error.delresponse)
            }



        }

    },

    mutations: {
        SET_USER(state, data) {
            //state.currentUser = data.user;
            state.apiToken = data.token;
            state.btnText = "Logout"
            localStorage.setItem("user", JSON.stringify(data.user));
            localStorage.setItem("token", data.token);
            localStorage.setItem("isLoggedIn", "true");
            // state.currentUser = localStorage.getItem('user');
            state.currentUser = data.user;
            state.userProfile = JSON.parse(state.currentUser);
            // if (localStorage.getItem('isLoggedIn')) {
            //     state.btnText = "Logout"
            // }


        },
        CLEAR_USER(state) {
            state.currentUser = null
            state.apiToken = null
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            localStorage.removeItem("isLoggedIn");
            // if (!localStorage.getItem('isLoggedIn')) {
            //     state.btnText = "Login"
            // }
            if (localStorage.getItem('isLoggedIn')) {
                state.btnText = "Logout";
            } else {
                state.btnText = "Login";
            }
        },
        updateUserProfileMutation: (state, data) => {

            localStorage.setItem("user", JSON.stringify(data.user));
            state.currentUser = data.user;
            console.log(state.currentUser, "Updated Current User");
        },

        SET_USERr(state) {
            if (localStorage.getItem('isLoggedIn')) {
                state.btnText = "Logout";
            } else {
                state.btnText = "Login";
            }
        },

        verifyUserMutate: (state, user) => {
            state.currentUser = user;
            state.apiToken = user.api_token;

            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("token", user.api_token);
        }
    }
})
export default store
