import 'bootstrap/dist/css/bootstrap.css';
import { createApp } from 'vue'
// import {createStore} from 'vuex'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import App from './App.vue'
import Vue3Autocounter from 'vue3-autocounter';
import router from "./router/index"
//import 'bootstrap/dist/js/bootstrap.bundle'
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel';
import store from './store/index'; // Import your store
import Vue3Toasity from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';


const app = createApp(App);

app.use(VueSweetalert2);
app.use(Vue3Toasity, {
transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
});
app.use(store); // Register the store
app.config.productionTip = false;
app.use(router);

import 'bootstrap/dist/js/bootstrap.js';
app.component('vue3-autocounter', Vue3Autocounter)

app.mount('#app')

