<template>
  <section>
    <!-- <Navbar02 /> -->
    <section>
      <!-- Start Page Title Section -->
      <!-- <div class="page-title-area item-bg1 aboutbg">
        <div class="d-table">
          <div class="d-table-cell">
            <div class="container">
              <div class="page-title-content">
                <h2>About Us</h2>
                <ul>
                  <li><a href="/">Home</a></li>
                  <li>About Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- End Page Title Section -->
    </section>

     <!-- Start Terms & Conditions Section -->
     <section class="terms-section ptb-100">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-8 col-sm-12">
            <div class="terms-conditions-info">
              <h3>About TrackPal</h3>
              <p>
                TrackPal is more than a travel app; it’s your ultimate travel
                companion. We pride ourselves on being a ground-breaking
                tracking app that redefines connections during journeys.
                TrackPal is designed with precision, offering real-time updates
                and accurate location tracking for seamless connections with
                your family and friends. Our mission is to elevate travel
                experiences by providing unparalleled peace of mind and
                strengthening the bonds created through shared adventures. We’re
                passionate about intertwining technology with human connections,
                ensuring hassle-free coordination and an added layer of security
                during your journey. With innovation at our core, TrackPal is
                committed to shaping a world where every travel story is
                memorable and reassuringly interconnected.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <img src="../../public/img/TrackPal-Highlight.png" alt />
          </div>
        </div>
        <div class="terms-conditions-info">
          <h3>What TrackPal Offers:</h3>
          <p>
            TrackPal is your all-in-one solution for seamless travel
            experiences. With a primary focus on keeping you connected and
            secure throughout your journeys, TrackPal offers a number of
            features that redefine the way you travel, including:
          </p>
          <div class="aboutul">
            <li>
              <strong>Keep a Track:</strong> Experience real-time location
              tracking, enabling instant updates on participants’ whereabouts
              after they have accepted your invitation.
            </li>
            <li>
              <strong>Tracking:</strong> Stay connected during meetups,
              adventures, or any group activity. Seamlessly track each other's
              movements for teamwork and safety.
            </li>
            <li>
              <strong>Location:</strong> Easily connect with loved ones by
              sharing your real-time location. As a host, enable tracking for
              friends and family throughout your journey.
            </li>
            <li>
              <strong>Shared Location:</strong> Easily connect with loved ones
              by sharing your real-time location. As a host, enable tracking for
              friends and family throughout your journey.
            </li>
          </div>

          <p>
            The TrackPal app ensures safety with geofencing alerts, allowing
            tailored notifications for specific locations. It also prioritizes
            personal tracking, granting the freedom to share your location
            securely with trusted contacts.
          </p>
          <p>
            Furthermore, TrackPal facilitates effortless communication through
            in-app chatting, eliminating the need for multiple platforms. The
            app’s history and reports feature provides comprehensive insights
            into your travel history, offering detailed information about past
            locations, routes, and timelines.
          </p>
          <p>
            Whether it’s group tracking for teamwork, business trips, or
            seamless coordination during family vacations, TrackPal ensures that
            a journey is not just about the destination but the connections made
            along the way.
          </p>
        </div>
      </div>
    </section>
    <!-- End Terms & Conditions Section -->
    <!-- Start Contact Info Section -->
    <!-- <section class="contact-info-wrapper bg-grey">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title">
              <h6 class="sub-title">Find Us</h6>
              <h2>Contact Info</h2>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="contact-info-content">
              <h5>USA Headquarter</h5>
              <p>
                304 NW St Homestead, Florida, Melrose Street, Water Mill, 76B
                Overlook Drive Chester, PA 19013, Flemingsburg USA.
              </p>
              <a href="tel:12345678">080 707 555-321</a>
              <a href="mailto:demo@example.com">demo@example.com</a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="contact-info-content">
              <h5>New York Office</h5>
              <p>
                1540 Pecks Ridge Tilton Rd Flemingsburg, Kentucky(KY), 4104188
                Fulton Street Blackwood, NJ 08012, London.
              </p>
              <a href="tel:12345678">080 707 555-321</a>
              <a href="mailto:demo@example.com">demo@example.com</a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="contact-info-content">
              <h5>Panama Office</h5>
              <p>
                103 Richard Ave Ashville, Ohio, Water Mill,3468 16th Hwy
                Pangburn, Arkansas(AR), Charolais Ashville, Virginia, Panama.
              </p>
              <a href="tel:12345678">080 707 555-321</a>
              <a href="mailto:demo@example.com">demo@example.com</a>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- End Contact Info Section -->

    <!-- Start Map Section -->
    <!-- <div class="map-area">
      <div class="map-content">
        <div class="map-canvas" id="contact-map"></div>
      </div>
    </div> -->
    <!-- End Map Section -->
    <!-- <Footer /> -->
  </section>
</template>

<script>
import { ref } from "vue";
// import Navbar02 from "../components/Navbar02.vue";
// import Footer from "../components/Footer.vue";
export default {
  components: {
    // Navbar02,
    // Footer,
  },
  setup() {
    const icons = ref([
      { name: "alternate_email", text: "by email" },
      { name: "local_phone", text: "by phone" },
      { name: "local_post_office", text: "by post" },
      { name: "local_fire_department", text: "by smoke signal" },
    ]);

    return { icons };
  },
};
</script>

<style>
.aboutbg {
  background: url("../../public/img/aboutbanner.jpg") no-repeat bottom center;
  background-size: cover;
  background-color: #820f87;
}
.contact ul {
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  max-width: 400px;
  margin: 60px auto;
}
.contact li {
  list-style-type: none;
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  line-height: 1.5em;
}
</style>
