<template>
  <!-- dsfd -->
  <section>
    <Navbar02 />
    <Header />
    <section>
      <Services />
    </section>
    <section ref="triggerSection03">
      <About :trigerVal="trigerVal" v-if="trigshow" />
    </section>
    <section ref="triggerSection">
      <CounterArea :trigerVal="trigerVal" v-if="trigshow" />
    </section>

    <Whychooseus :trigerVal="trigerVal" v-if="trigshow" />

    <Testimonials />
    <DigitalSection />
    <FAQ />
    <Footer />
  </section>
</template>

<script>
import Navbar02 from "./../components/Navbar02.vue";
import Header from "./../components/Header.vue";
import Services from "./../components/ServiceBox.vue";
import Footer from "./../components/Footer.vue";
import About from "./../components/About.vue";
import CounterArea from "./../components/CounterArea.vue";
import Whychooseus from "./../components/whychooseus.vue";
import Testimonials from "./../components/Testimonials.vue";
import FAQ from "./../components/FAQ.vue";
import DigitalSection from "./../components/DigitalSection.vue";

export default {
  name: "Home",
  components: {
    Navbar02,
    Header,
    Footer,
    Services,
    About,
    CounterArea,
    Whychooseus,
    Testimonials,
    FAQ,
    DigitalSection,
  },

  data() {
    return {
      trigerVal: true,
      trigshow: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("scroll", this.handleScroll02);
    window.addEventListener("scroll", this.handleScroll03);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("scroll", this.handleScroll02);
    window.removeEventListener("scroll", this.handleScroll03);
  },
  methods: {
    handleScroll() {
      const section = this.$refs.triggerSection;

      if (!section || this.triggered) return;

      const sectionRect = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (sectionRect.top < windowHeight && sectionRect.bottom > 0) {
        // Section is in view
        this.triggered = true; // Trigger only once
        this.trigshow = true;
      }
    },

    handleScroll02() {
      const section = this.$refs.triggerSection02;

      if (!section || this.triggered) return;

      const sectionRect = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (sectionRect.top < windowHeight && sectionRect.bottom > 0) {
        // Section is in view
        this.triggered = true; // Trigger only once
        this.trigshow = true;
      }
    },

    handleScroll03() {
      const section = this.$refs.triggerSection03;

      if (!section || this.triggered) return;

      const sectionRect = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (sectionRect.top < windowHeight && sectionRect.bottom > 0) {
        // Section is in view
        this.triggered = true; // Trigger only once
        this.trigshow = true;
      }
    },
  },
};
</script>

<style>
.go-top {
  background-color: #b61f85;
}
.faq-accordion .accordion .accordion-item .accordion-title {
  border-left: 3px solid #b61f85;
}
.faq-accordion .accordion .accordion-item .active.accordion-title {
  color: #fff;
  background-color: #b61f85;
}
</style>
