<template>
  <section>
    <!-- Start Overview Section -->
    <section class="overview-section section-paddingg">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <transition appear @enter="enter" @before-enter="beforeEnter">
              <div class="overview-image">
                <img src="img/step-phone3.png" alt="image" />
              </div>
            </transition>
          </div>
          <div class="col-lg-6">
            <transition appear @enter="enter02" @before-enter="beforeEnter02">
              <div class="overview-content">
                <div class="about-content">
                  <h6 class="sub-title">How The App Works</h6>
                  <h2>Begin Your Journey with 4 Simple Steps</h2>
                </div>

                <p>
                  We make sure that every stage of your experience is
                  hassle-free and accessible; you just need to follow these four
                  simple steps:
                </p>
                <ul class="features-list">
                  <li><span>Download our app on your mobile device.</span></li>
                  <li>
                    <span
                      >Login to your account and customize tracking
                      preferences.</span
                    >
                  </li>
                  <li>
                    <span
                      >Track and monitor locations from your device in real
                      time.</span
                    >
                  </li>
                  <li>
                    <span
                      >Get alerts and notifications tailored to your
                      needs.</span
                    >
                  </li>
                </ul>

                <div class="about-btn-box">
                  <div class="row align-items-center">
                    <div class="col-6">
                      <img src="img/gplay-btn.png" alt="Google Play" />
                    </div>
                    <div class="col-6">
                      <img src="img/apple-btn.png" alt="Apple Play" />
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default {
  methods: {
    beforeEnter(el) {
      el.style.opacity = "0";
      el.style.transform = "translateY(-700px)";
    },
    enter(el) {
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
          start: "top center",
          toggleActions: "restart resume  reverse play",
        },
        opacity: 1,
        y: 30,
        duration: 3,

        delay: 0.4,
        ease: "bounce.out",
      });
    },

    beforeEnter02(el) {
      el.style.opacity = "0";
      el.style.transform = "translateY(600px)";
    },
    enter02(el) {
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,

          toggleActions: "restart resume  reverse pause",
        },
        opacity: 1,
        y: 0,
        duration: 3,

        delay: 0.4,
        ease: "bounce.out",
      });
    },
  },
};
</script>

<style>
.about {
  max-width: 600px;
  margin: 0px auto;
}
.overview-content .features-list li span {
  border-left: 2px solid #b61f85 !important;
}
.overview-content .features-list li span::before {
  background: #b61f85 !important;
}
.section-paddingg {
  padding: 50px 0 0 0;
}
</style>
