<template>
  <section>
    <!-- Start Services Section -->
    <section class="services-section section-padding">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <transition appear @before-enter="beforeEnter02" @enter="enter02">
              <div class="section-title">
                <h6 class="sub-title">What We Bring to You</h6>
                <h2>Services Overview</h2>
              </div>
            </transition>
          </div>
          <!-- <transition-group appear @before-enter="beforeEnter" @enter="enter"> -->
          <transition-group appear @enter="enter">
            <div
              class="col-lg-4 col-md-6"
              v-for="(service, index) in services"
              :key="index"
              :data-index="index"
            >
              <div class="single-services-item">
                <div class="services-icon">
                  <i :class="service.iconClass"></i>
                </div>
                <h3>{{ service.heading }}</h3>
                <p>
                  {{ service.text }}
                </p>
                <!-- <div class="services-btn">
                  <a :href="service.link" class="read-more"
                    ><i class="bi bi-arrow-right-short"></i> Read More</a
                  >
                </div> -->
              </div>
            </div>
          </transition-group>
        </div>
      </div>
    </section>
    <!-- End Services Section -->
  </section>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default {
  data() {
    return {
      services: [
        {
          iconClass: "flaticon-project-management",
          heading: "Real-time Location Tracking",
          text: "With precision and immediacy, the Real-time Travel Location Tracking feature provides instant updates on the current whereabouts of fellow travelers, transforming the way you navigate and share your adventures.",
          link: "#",
        },
        {
          iconClass: "flaticon-development",
          heading: "Geofencing Alerts",
          text: "Whether it's ensuring your child's safety, monitoring a valuable asset, or receiving notifications when loved ones arrive at or depart from specific locations, Geofencing Alerts provide updates tailored to your preferences. ",
          link: "#",
        },
        {
          iconClass: "flaticon-analytics",
          heading: "Personal Tracking",
          text: "We ensure that your location is readily accessible to trusted contacts, providing an added layer of security. Customize your privacy settings, choose who sees your location, and enjoy the freedom of staying connected without compromising your comfort.",
          link: "#",
        },
        {
          iconClass: "flaticon-cpu-1",
          heading: "Group Travel",
          text: "From family vacations and friends’ meet-ups to business trips, this feature fosters a sense of unity and ensures that everyone is on the same page. Seamlessly coordinate and stay connected with all members of your travel in real time.",
          link: "#",
        },
        {
          iconClass: "flaticon-coding",
          heading: "In-App Chatting",
          text: "Eliminating the need for multiple messaging platforms, we facilitate seamless communication. The In-App Chatting feature lets you stay connected effortlessly with your fellow travelers and ensure better coordination with the app.",
          link: "#",
        },
        {
          iconClass: "flaticon-mobile-app",
          heading: "History and Reports",
          text: "This feature compiles a comprehensive record of your journeys, providing detailed insights into past locations, routes, and timelines. The intuitive interface lets you effortlessly navigate through your travel history, giving you a nuanced understanding of your journeys.",
          link: "#",
        },
      ],
    };
  },

  methods: {
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = "translateY(100px)";
    },
    enter(el, done) {
      gsap.from(el, {
        opacity: 1,
        scrollTrigger: {
          opacity: 0,
          y: 100,
          toggleActions: "restart pause reverse play",
        },
        y: 100,
        duration: 0.8,
        onComplete: done,
        delay: el.dataset.index * 0.2,
        stagger: 1,
      });
    },

    beforeEnter02(el2) {
      el2.style.opacity = 0;
      el2.style.transform = "translateY(-90px)";
    },

    enter02(el2, done) {
      gsap.to(el2, {
        opacity: 1,
        scrollTrigger: {
          opacity: 1,
          y: 90,
          toggleActions: "restart pause reverse play",
        },
        y: 0,
        duration: 3,
        onComplete: done,
        ease: "bounce.out",
        stagger: 1,
      });
    },

    // scrollAnimation() {
    //   const myanimation = gsap.timeline({
    //     scrollTrigger: {
    //       trigger: ".services-section section-padding",

    //       markers: true,
    //       scrub: true,
    //       pin: true,
    //       opacity: 1,
    //     },
    //   });
    //   // myanimation.from(".single-services-item", {
    //   //   y: 100,
    //   //   duration: 2,
    //   // });
    //   myanimation.to(".single-services-item", {
    //     y: 0,
    //     duration: 0.8,

    //     delay: ".single-services-item".dataset.index * 0.2,
    //   });

    //   this.scrollHandler = () => {
    //     if (window.scrollY > this.$el.offsetTop + 300 - window.innerHeight) {
    //       myanimation.play(console.log("this is playing"));
    //     } else {
    //       myanimation.reverse(console.log("this is not playing"), {
    //         opacity: 0,
    //       });
    //     }
    //   };

    //   window.addEventListener("scroll", this.scrollHandler);
    // },
    // removeScrollListener() {
    //   window.removeEventListener("scroll", this.scrollHandler);
    // },
  },
};
</script>

<style>
.about {
  max-width: 600px;
  margin: 20px auto;
}
</style>
