<template>
  <section>
    <!-- <Navbar02 /> -->
    <section>
      <!-- Start Page Title Section -->
      <!-- <div class="page-title-area item-bg1 termsbg">
        <div class="d-table">
          <div class="d-table-cell">
            <div class="container">
              <div class="page-title-content">
                <h2>Terms & Conditions</h2>
                <ul>
                  <li><a href="index-2.html">Home</a></li>
                  <li>Terms & Conditions</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- End Page Title Section -->
    </section>

    <!-- Start Terms & Conditions Section -->
    <section class="terms-section ptb-100">
      <div class="container">
        <div class="terms-conditions-info">
          <h3>AGREEMENT TO OUR LEGAL TERMS</h3>
          <p>
            <strong>TO OUR LEGAL TERMS</strong><br />
            We are TrackPal ("Company," "we," "us," "our"), a company registered
            in New York, United States at 526 Melrose Street, Water Mill, 11976
            New York, Water Mill, NY.<br />
            We operate the website https://trackpal.staginganideos.com (the
            "Site"), the mobile application TrackPal (the "App"), as well as any
            other related products and services that refer or link to these
            legal terms (the "Legal Terms") (collectively, the "Services").<br />
            You can contact us by <br />
            Phone: <a href="tel:+1080707555321">(+1)080 707 555-321</a>, <br />
            Email: <a href="mailto:info@trackpal.co">info@trackpal.co</a
            ><br />
            By mail: 526 Melrose Street, Water Mill, 11976 New York, Water Mill,
            NY, United States.<br />
            These Legal Terms constitute a legally binding agreement made
            between you, whether personally or on behalf of an entity ("you"),
            and TrackPal, concerning your access to and use of the Services. You
            agree that by accessing the Services, you have read, understood, and
            agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE
            WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED
            FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
          </p>
          <p>
            We will provide you with prior notice of any scheduled changes to
            the Services you are using. The modified Legal Terms will become
            effective upon posting or notifying you by as stated in the email
            message. By continuing to use the Services after the effective date
            of any changes, you agree to be bound by the modified terms.
          </p>
          <p>
            The Services are intended for users who are at least 13 years of
            age. All users who are minors in the jurisdiction in which they
            reside (generally under the age of 18) must have the permission of,
            and be directly supervised by, their parent or guardian to use the
            Services. If you are a minor, you must have your parent or guardian
            read and agree to these Legal Terms prior to you using the Services.
          </p>
          <p>
            We recommend that you print a copy of these Legal Terms for your
            records.
          </p>
          <h3>TABLE OF CONTENTS</h3>
          <ol>
            <li><a href="#tab01" class="clickable" data-target="tab01"
                >OUR SERVICES</a></li>
            <li><a href="#tab02" class="clickable" data-target="tab02"
                >INTELLECTUAL PROPERTY RIGHTS</a></li>
            <li><a href="#tab03" class="clickable" data-target="tab03"
                >USER REPRESENTATION</a></li>
            <li><a href="#tab04" class="clickable" data-target="tab04"
                >USER REGISTRATION</a></li>
            <li><a href="#tab05" class="clickable" data-target="tab05"
                >PURCHASES AND PAYMENT</a></li>
            <li><a href="#tab06" class="clickable" data-target="tab06"
                >CANCELLATION</a></li>
          <li><a href="#tab07" class="clickable" data-target="tab07"
                >SOFTWARE</a></li>
                <li><a href="#tab08" class="clickable" data-target="tab08"
                >PROHIBITED ACTIVITIES</a></li>
            <li><a href="#tab09" class="clickable" data-target="tab09"
                >USER GENERATED CONTRIBUTIONS</a></li>
            <li><a href="#tab10" class="clickable" data-target="tab10"
                >CONTRIBUTION LICENSE</a></li>
            <li><a href="#tab11" class="clickable" data-target="tab11"
                >MOBILE APPLICATION LICENSE</a></li>
            <li><a href="#tab12" class="clickable" data-target="tab12"
                >THIRD-PARTY WEBSITES AND CONTENT</a></li>
            <li><a href="#tab13" class="clickable" data-target="tab13"
                >SERVICES MANAGEMENT</a></li>
            <li><a href="#tab14" class="clickable" data-target="tab14"
                >PRIVACY POLICY</a></li>
            <li><a href="#tab15" class="clickable" data-target="tab15"
                >TERM AND TERMINATION</a></li>
            <li><a href="#tab16" class="clickable" data-target="tab16"
                >MODIFICATIONS AND INTERRUPTIONS</a></li>
            <li><a href="#tab17" class="clickable" data-target="tab17"
                >GOVERNING LAW</a></li>
           <li><a href="#tab18" class="clickable" data-target="tab18"
                >DISPUTE RESOLUTION</a></li>
            <li><a href="#tab19" class="clickable" data-target="tab19"
                >CORRECTIONS</a></li>
           <li><a href="#tab20" class="clickable" data-target="tab20"
                >DISCLAIMER</a></li>
          <li><a href="#tab21" class="clickable" data-target="tab21"
                >LIMITATIONS OF LIABILITY</a></li>
            <li><a href="#tab22" class="clickable" data-target="tab22"
                >INDEMNIFICATION</a></li>
            <li><a href="#tab23" class="clickable" data-target="tab23"
                >USER DATA</a></li>
           <li><a href="#tab24" class="clickable" data-target="tab24"
                >ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</a></li>
            <li><a href="#tab25" class="clickable" data-target="tab25"
                >CONTACT US</a></li>
         </ol>

          <h3 id="tab01" class="target-block">1. OUR SERVICES</h3>
          <p>
            The information provided when using the Services is not intended for
            distribution to or use by any person or entity in any jurisdiction
            or country where such distribution or use would be contrary to law
            or regulation or which would subject us to any registration
            requirement within such jurisdiction or country. Accordingly, those
            persons who choose to access the Services from other locations do so
            on their own initiative and are solely responsible for compliance
            with local laws, if and to the extent local laws are applicable.
          </p>
          <p>
            The Services are not tailored to comply with industry-specific
            regulations (Health Insurance Portability and Accountability Act
            (HIPAA), Federal Information Security Management Act (FISMA), etc.),
            so if your interactions would be subjected to such laws, you may not
            use the Services. You may not use the Services in a way that would
            violate the Gramm-Leach-Bliley Act (GLBA).
          </p>
          <h3 id="tab02" class="target-block">2. INTELLECTUAL PROPERTY RIGHTS</h3>
          <p><strong>Our intellectual property</strong></p>
          <p>
            We are the owner or the licensee of all intellectual property rights
            in our Services, including all source code, databases,
            functionality, software, website designs, audio, video, text,
            photographs, and graphics in the Services (collectively, the
            "Content"), as well as the trademarks, service marks, and logos
            contained therein (the "Marks").
          </p>
          <p>
            Our Content and Marks are protected by copyright and trademark laws
            (and various other intellectual property rights and unfair
            competition laws) and treaties in the United States and around the
            world.
          </p>
          <p>
            The Content and Marks are provided in or through the Services "AS
            IS" for your personal, non- commercial use only.
          </p>
          <p><strong>Your use of our Services</strong></p>
          <p>
            Subject to your compliance with these Legal Terms, including the
            "PROHIBITED ACTIVITIES" section below, we grant you a non-exclusive,
            non-transferable, revocable license to:
          </p>
          <ul>
            <li>access the Services; and</li>
            <li>
              download or print a copy of any portion of the Content to which
              you have properly gained access.
            </li>
          </ul>
          <p>solely for your personal, non-commercial use.</p>
          <p>
            Except as set out in this section or elsewhere in our Legal Terms,
            no part of the Services and no Content or Marks may be copied,
            reproduced, aggregated, republished, uploaded, posted, publicly
            displayed, encoded, translated, transmitted, distributed, sold,
            licensed, or otherwise exploited for any commercial purpose
            whatsoever, without our express prior written permission.
          </p>
          <p>
            If you wish to make any use of the Services, Content, or Marks other
            than as set out in this section or elsewhere in our Legal Terms,
            please address your request to: <a href="mailto:info@trackpal.co">info@trackpal.co.</a> If we ever grant
            you the permission to post, reproduce, or publicly display any part
            of our Services or Content, you must identify us as the owners or
            licensors of the Services, Content, or Marks and ensure that any
            copyright or proprietary notice appears or is visible on posting,
            reproducing, or displaying our Content.
          </p>
          <p>
            We reserve all rights not expressly granted to you in and to the
            Services, Content, and Marks.
          </p>
          <p>
            Any breach of these Intellectual Property Rights will constitute a
            material breach of our Legal Terms and your right to use our
            Services will terminate immediately.
          </p>
          <p><strong>Your submissions and contributions</strong></p>
          <p>
            Please review this section and the "PROHIBITED ACTIVITIES"; section
            carefully prior to using our Services to understand the (a) rights
            you give us and (b) obligations you have when you post or upload any
            content through the Services.
          </p>
          <p>
            Submissions: By directly sending us any question, comment,
            suggestion, idea, feedback, or other information about the Services
            (&quot;Submissions&quot;), you agree to assign to us all
            intellectual property rights in such Submission. You agree that we
            shall own this Submission and be entitled to its unrestricted use
            and dissemination for any lawful purpose, commercial or otherwise,
            without acknowledgment or compensation to you.
          </p>
          <p>
            Contributions: The Services may invite you to chat, contribute to,
            or participate in blogs, message boards, online forums, and other
            functionality during which you may create, submit, post, display,
            transmit, publish, distribute, or broadcast content and materials to
            us or through the Services, including but not limited to text,
            writings, video, audio, photographs, music, graphics, comments,
            reviews, rating suggestions, personal information, or other material
            (&quot;Contributions&quot;). Any Submission that is publicly posted
            shall also be treated as a Contribution.
          </p>
          <p>
            You understand that Contributions may be viewable by other users of
            the Services and possibly through third-party websites.
          </p>
          <p>
            When you post Contributions, you grant us a license (including use
            of your name, trademarks, and logos): By posting any Contributions,
            you grant us an unrestricted, unlimited, irrevocable, perpetual,
            non-exclusive, transferable, royalty-free, fully-paid, worldwide
            right, and license to: use, copy, reproduce, distribute, sell,
            resell, publish, broadcast, retitle, store, publicly perform,
            publicly display, reformat, translate, excerpt (in whole or in
            part), and exploit your Contributions (including, without
            limitation, your image, name, and voice) for any purpose,
            commercial, advertising, or otherwise, to prepare derivative works
            of, or incorporate into other works, your Contributions, and to
            sublicense the licenses granted in this section. Our use and
            distribution may occur in any media formats and through any media
            channels.
          </p>
          <p>
            This license includes our use of your name, company name, and
            franchise name, as applicable, and any of the trademarks, service
            marks, trade names, logos, and personal and commercial images you
            provide.
          </p>
          <p>
            You are responsible for what you post or upload: By sending us
            Submissions and/or posting Contributions through any part of the
            Services or making Contributions accessible through the Services by
            linking your account through the Services to any of your social
            networking accounts, you:
          </p>
          <ul>
            <li>
              confirm that you have read and agree with our &quot;PROHIBITED
              ACTIVITIES&quot; and will not post, send, publish, upload, or
              transmit through the Services any Submission nor post any
              Contribution that is illegal, harassing, hateful, harmful,
              defamatory, obscene, bullying, abusive, discriminatory,
              threatening to any person or group, sexually explicit, false,
              inaccurate, deceitful, or misleading:
            </li>
            <li>
              to the extent permissible by applicable law, waive any and all
              moral rights to any such Submission and/or Contribution;
            </li>
            <li>
              warrant that any such Submission and/or Contributions are original
              to you or that you have the necessary rights and licenses to
              submit such Submissions and/or Contributions and that you have
              full authority to grant us the above-mentioned rights in relation
              to your Submissions and/or Contributions; and
            </li>
            <li>
              warrant and represent that your Submissions and/or Contributions
              do not constitute confidential information.
            </li>
          </ul>
          <p>
            You are solely responsible for your Submissions and/or Contributions
            and you expressly agree to reimburse us for any and all losses that
            we may suffer because of your breach of (a) this section, (b) any
            third party's intellectual property rights, or (c) applicable law.
          </p>
          <p>
            We may remove or edit your Content: Although we have no obligation
            to monitor any Contributions, we shall have the right to remove or
            edit any Contributions at any time without notice if in our
            reasonable opinion we consider such Contributions harmful or in
            breach of these Legal Terms. If we remove or edit any such
            Contributions, we may also suspend or disable your account and
            report you to the authorities.
          </p>
          <h3 id="tab03" class="target-block">3. USER REPRESENTATIONS</h3>
          <p>
            By using the Services, you represent and warrant that: (1) all
            registration information you submit will be true, accurate, current,
            and complete; (2) you will maintain the accuracy of such information
            and promptly update such registration information as necessary; (3)
            you have the legal capacity and you agree to comply with these Legal
            Terms; (4) you are not under the age of 13; (5) you are not a minor
            in the jurisdiction in which you reside, or if a minor, you have
            received parental permission to use the Services; (6) you will not
            access the Services through automated or non- human means, whether
            through a bot, script or otherwise; (7) you will not use the
            Services for any illegal or unauthorized purpose; and (8) your use
            of the Services will not violate any applicable law or regulation.
          </p>
          <p>
            If you provide any information that is untrue, inaccurate, not
            current, or incomplete, we have the right to suspend or terminate
            your account and refuse any and all current or future use of the
            Services (or any portion thereof).
          </p>
          <h3 id="tab04" class="target-block">4. USER REGISTRATION</h3>
          <p>
            You may be required to register to use the Services. You agree to
            keep your password confidential and will be responsible for all use
            of your account and password. We reserve the right to remove,
            reclaim, or change a username you select if we determine, in our
            sole discretion, that such username is inappropriate, obscene, or
            otherwise objectionable.
          </p>
          <h3 id="tab05" class="target-block">5. PURCHASES AND PAYMENT</h3>
          <p>We accept the following forms of payment:</p>
          <p>- Google Pay</p>
          <p>- Apple Pay</p>
          <p>
            You agree to provide current, complete, and accurate purchase and
            account information for all purchases made via the Services. You
            further agree to promptly update account and payment information,
            including email address, payment method, and payment card expiration
            date, so that we can complete your transactions and contact you as
            needed. Sales tax will be added to the price of purchases as deemed
            required by us. We may change prices at any time. All payments shall
            be in dollars
          </p>
          <p>
            You agree to pay all charges at the prices then in effect for your
            purchases and any applicable shipping fees, and you authorize us to
            charge your chosen payment provider for any such amounts upon
            placing your order. We reserve the right to correct any errors or
            mistakes in pricing, even if we have already requested or received
            payment.
          </p>
          <p>
            We reserve the right to refuse any order placed through the
            Services. We may, in our sole discretion, limit or cancel quantities
            purchased per person, per household, or per order. These
            restrictions may include orders placed by or under the same customer
            account, the same payment method, and/or orders that use the same
            billing or shipping address. We reserve the right to limit or
            prohibit orders that, in our sole judgment, appear to be placed by
            dealers, resellers, or distributors.
          </p>
          <h3 id="tab06" class="target-block">6. CANCELLATION</h3>
          <p>
            All purchases are non-refundable. You can cancel your subscription
            at any time by logging into your account. Your cancellation will
            take effect at the end of the current paid term.
          </p>
          <p>
            If you are unsatisfied with our Services, please email us at
            <a href="mailto:info@trackpal.co">info@trackpal.co</a> or call us
            at <a href="tel:+1080707555321">(+1)080707 555-321</a>.
          </p>
          <h3 id="tab07" class="target-block">7. SOFTWARE</h3>
          <p>
            We may include software for use in connection with our Services. If
            such software is accompanied by an end user license agreement
            (&quot;EULA&quot;), the terms of the EULA will govern your use of
            the software. If such software is not accompanied by a EULA, then we
            grant to you a non-exclusive, revocable, personal, and
            non-transferable license to use such software solely in connection
            with our services and in accordance with these Legal Terms. Any
            software and any related documentation is provided &quot;AS IS&quot;
            without warranty of any kind, either express or implied, including,
            without limitation, the implied warranties of merchantability,
            fitness for a particular purpose, or non-infringement. You accept
            any and all risk arising out of use or performance of any software.
            You may not reproduce or redistribute any software except in
            accordance with the EULA or these Legal Terms.
          </p>
          <h3 id="tab08" class="target-block">8. PROHIBITED ACTIVITIES</h3>
          <p>
            You may not access or use the Services for any purpose other than
            that for which we make the Services available. The Services may not
            be used in connection with any commercial endeavors except those
            that are specifically endorsed or approved by us.
          </p>
          <p>As a user of the Services, you agree not to:</p>
          <ul>
            <li>
              Systematically retrieve data or other content from the Services to
              create or compile, directly or indirectly, a collection,
              compilation, database, or directory without written permission
              from us.
            </li>
            <li>
              Trick, defraud, or mislead us and other users, especially in any
              attempt to learn sensitive account information such as user
              passwords.
            </li>
            <li>
              Circumvent, disable, or otherwise interfere with security-related
              features of the Services, including features that prevent or
              restrict the use or copying of any Content or enforce limitations
              on the use of the Services and/or the Content contained therein.
            </li>
            <li>
              Disparage, tarnish, or otherwise harm, in our opinion, us and/or
              the Services.
            </li>
            <li>
              Use any information obtained from the Services in order to harass,
              abuse, or harm another person.
            </li>
            <li>
              Make improper use of our support services or submit false reports
              of abuse or misconduct.
            </li>
            <li>
              Use the Services in a manner inconsistent with any applicable laws
              or regulations.
            </li>
            <li>
              Engage in unauthorized framing of or linking to the Services.
            </li>
            <li>
              Upload or transmit (or attempt to upload or to transmit) viruses,
              Trojan horses, or other material, including excessive use of
              capital letters and spamming (continuous posting of repetitive
              text), that interferes with any party's uninterrupted use and
              enjoyment of the Services or modifies, impairs, disrupts, alters,
              or interferes with the use, features, functions, operation, or
              maintenance of the Services.
            </li>
            <li>
              Engage in any automated use of the system, such as using scripts
              to send comments or messages, or using any data mining, robots, or
              similar data gathering and extraction tools.
            </li>
            <li>
              Delete the copyright or other proprietary rights notice from any
              Content.
            </li>
            <li>
              Attempt to impersonate another user or person or use the username
              of another user.
            </li>
            <li>
              Upload or transmit (or attempt to upload or to transmit) any
              material that acts as a passive or active information collection
              or transmission mechanism, including without limitation, clear
              graphics interchange formats (&quot;gifs&quot;), 1x1 pixels, web
              bugs, cookies, or other similar devices (sometimes referred to as
              &quot;spyware&quot; or &quot;passive collection mechanisms&quot;
              or &quot;pcms&quot;).
            </li>
            <li>
              Interfere with, disrupt, or create an undue burden on the Services
              or the networks or services connected to the Services.
            </li>
            <li>
              Harass, annoy, intimidate, or threaten any of our employees or
              agents engaged in providing any portion of the Services to you.
            </li>
            <li>
              Attempt to bypass any measures of the Services designed to prevent
              or restrict access to the Services, or any portion of the
              Services.
            </li>
            <li>
              Copy or adapt the Services' software, including but not limited to
              Flash, PHP, HTML, JavaScript, or other code.
            </li>
            <li>
              Except as permitted by applicable law, decipher, decompile,
              disassemble, or reverse engineer any of the software comprising or
              in any way making up a part of the Services.
            </li>
            <li>
              Except as may be the result of standard search engine or Internet
              browser usage, use, launch, develop, or distribute any automated
              system, including without limitation, any spider, robot, cheat
              utility, scraper, or offline reader that accesses the Services, or
              use or launch any unauthorized script or other software.
            </li>
            <li>
              Use a buying agent or purchasing agent to make purchases on the
              Services.
            </li>
            <li>
              Make any unauthorized use of the Services, including collecting
              usernames and/or email addresses of users by electronic or other
              means for the purpose of sending unsolicited email, or creating
              user accounts by automated means or under false pretenses.
            </li>
            <li>
              Use the Services as part of any effort to compete with us or
              otherwise use the Services and/or the Content for any
              revenue-generating endeavor or commercial enterprise.
            </li>
            <li>Sell or otherwise transfer your profile.</li>
            <li>
              Use the Services to advertise or offer to sell goods and services.
            </li>
          </ul>

          <h3 id="tab09" class="target-block">9. USER GENERATED CONTRIBUTIONS</h3>
          <p>
            The Services may invite you to chat, contribute to, or participate
            in blogs, message boards, online forums, and other functionality,
            and may provide you with the opportunity to create, submit, post,
            display, transmit, perform, publish, distribute, or broadcast
            content and materials to us or on the Services, including but not
            limited to text, writings, video, audio, photographs, graphics,
            comments, suggestions, or personal information or other material
            (collectively, &quot;Contributions&quot;). Contributions may be
            viewable by other users of the Services and through third-party
            websites. As such, any Contributions you transmit may be treated as
            non-confidential and non-proprietary. When you create or make
            available any Contributions, you thereby represent and warrant that:
          </p>
          <ul>
            <li>
              The creation, distribution, transmission, public display, or
              performance, and the accessing, downloading, or copying of your
              Contributions do not and will not infringe the proprietary rights,
              including but not limited to the copyright, patent, trademark,
              trade secret, or moral rights of any third party.
            </li>
            <li>
              You are the creator and owner of or have the necessary licenses,
              rights, consents, releases, and permissions to use and to
              authorize us, the Services, and other users of the Services to use
              your Contributions in any manner contemplated by the Services and
              these Legal Terms.
            </li>
            <li>
              You have the written consent, release, and/or permission of each
              and every identifiable individual person in your Contributions to
              use the name or likeness of each and every such identifiable
              individual person to enable inclusion and use of your
              Contributions in any manner contemplated by the Services and these
              Legal Terms.
            </li>
            <li>
              Your Contributions are not false, inaccurate, or misleading.
            </li>
            <li>
              Your Contributions are not unsolicited or unauthorized
              advertising, promotional materials, pyramid schemes, chain
              letters, spam, mass mailings, or other forms of solicitation.
            </li>
            <li>
              Your Contributions are not obscene, lewd, lascivious, filthy,
              violent, harassing, libelous, slanderous, or otherwise
              objectionable (as determined by us).
            </li>
            <li>
              Your Contributions do not ridicule, mock, disparage, intimidate,
              or abuse anyone.
            </li>
            <li>
              Your Contributions are not used to harass or threaten (in the
              legal sense of those terms) any other person and to promote
              violence against a specific person or class of people.
            </li>
            <li>
              Your Contributions do not violate any applicable law, regulation,
              or rule.
            </li>
            <li>
              Your Contributions do not violate the privacy or publicity rights
              of any third party.
            </li>
            <li>
              Your Contributions do not violate any applicable law concerning
              child pornography, or otherwise intended to protect the health or
              well-being of minors.
            </li>
            <li>
              Your Contributions do not include any offensive comments that are
              connected to race, national origin, gender, sexual preference, or
              physical handicap.
            </li>
            <li>
              Your Contributions do not otherwise violate, or link to material
              that violates, any provision of these Legal Terms, or any
              applicable law or regulation.
            </li>
          </ul>

          <p>
            Any use of the Services in violation of the foregoing violates these
            Legal Terms and may result in, among other things, termination or
            suspension of your rights to use the Services.
          </p>
          <h3 id="tab10" class="target-block">10. CONTRIBUTION LICENSE</h3>
          <p>
            By posting your Contributions to any part of the Services, you
            automatically grant, and you represent and warrant that you have the
            right to grant, to us an unrestricted, unlimited, irrevocable,
            perpetual, non-exclusive, transferable, royalty-free, fully-paid,
            worldwide right, and license to host, use, copy, reproduce,
            disclose, sell, resell, publish, broadcast, retitle, archive, store,
            cache, publicly perform, publicly display, reformat, translate,
            transmit, excerpt (in whole or in part), and distribute such
            Contributions (including, without limitation, your image and voice)
            for any purpose, commercial, advertising, or otherwise, and to
            prepare derivative works of, or incorporate into other works, such
            Contributions, and grant and authorize sublicenses of the foregoing.
            The use and distribution may occur in any media formats and through
            any media channels.
          </p>
          <p>
            This license will apply to any form, media, or technology now known
            or hereafter developed, and includes our use of your name, company
            name, and franchise name, as applicable, and any of the trademarks,
            service marks, trade names, logos, and personal and commercial
            images you provide. You waive all moral rights in your
            Contributions, and you warrant that moral rights have not otherwise
            been asserted in your Contributions
          </p>
          <p>
            We do not assert any ownership over your Contributions. You retain
            full ownership of all of your Contributions and any intellectual
            property rights or other proprietary rights associated with your
            Contributions. We are not liable for any statements or
            representations in your Contributions provided by you in any area on
            the Services. You are solely responsible for your Contributions to
            the Services and you expressly agree to exonerate us from any and
            all responsibility and to refrain from any legal action against us
            regarding your Contributions.
          </p>
          <p>
            We have the right, in our sole and absolute discretion, (1) to edit,
            redact, or otherwise change any Contributions; (2) to re-categorize
            any Contributions to place them in more appropriate locations on the
            Services; and (3) to pre-screen or delete any Contributions at any
            time and for any reason, without notice. We have no obligation to
            monitor your Contributions.
          </p>
          <h3 id="tab11" class="target-block">11. MOBILE APPLICATION LICENSE</h3>
          <p><strong>Use License</strong></p>
          <p>
            If you access the Services via the App, then we grant you a
            revocable, non-exclusive, non- transferable, limited right to
            install and use the App on wireless electronic devices owned or
            controlled by you, and to access and use the App on such devices
            strictly in accordance with the terms and conditions of this mobile
            application license contained in these Legal Terms. You shall not:
<ul>
            <li>
              except as permitted by applicable law, decompile, reverse
              engineer, disassemble, attempt to derive the source code of, or
              decrypt the App;
            </li><li>
               make any modification, adaptation, improvement,
              enhancement, translation, or derivative work from the App;
            </li><li>
              
              violate any applicable laws, rules, or regulations in connection
              with your access or use of the App;
            </li> <li>
              remove, alter, or obscure
              any proprietary notice (including any notice of copyright or
              trademark) posted by us or the licensors of the App;
            </li><li>
               use the App
              for any revenue-generating endeavor, commercial enterprise, or other
              purpose for which it is not designed or intended;
            </li> <li>
               make the App
              available over network or other environment permitting access or use
              by multiple devices or users at the same time;
            </li><li>
               use the App for
              creating a product, service, or software that is, directly or
              indirectly, competitive with or in any way a substitute for the App;
            </li>
            <li>
               use the App to send automated queries to any website or to send
              any unsolicited commercial email; or
            </li> <li>
               use any proprietary
              information or any of our interfaces or our other intellectual
              property in the design, development, manufacture, licensing, or
              distribution of any applications, accessories, or devices for use
              with the App.
            </li>
            </ul>
          </p>
          <h3>Apple and Android Devices</h3>
          <p>
            The following terms apply when you use the App obtained from either
            the Apple Store or Google Play (each an &quot;App Distributor&quot;)
            to access the Services:</p>
            <ul>
            <li>
               the license granted to you for our App
              is limited to a non-transferable license to use the application on a
              device that utilizes the Apple iOS or Android operating systems, as
              applicable, and in accordance with the usage rules set forth in the
              applicable App Distributor's terms of service;
            </li> <li>
               we are
              responsible for providing any maintenance and support services with
              respect to the App as specified in the terms and conditions of this
              mobile application license contained in these Legal Terms or as
              otherwise required under applicable law, and you acknowledge that
              each App Distributor has no obligation whatsoever to furnish any
              maintenance and support services with respect to the App;
            </li><li>
                in the
              event of any failure of the App to conform to any applicable
              warranty, you may notify the applicable App Distributor, and the App
              Distributor, in accordance with its terms and policies, may refund
              the purchase price, if any, paid for the App, and to the maximum
              extent permitted by applicable law, the App Distributor will have no
              other warranty obligation whatsoever with respect to the App;
            </li><li>
               
              you represent and warrant that (i) you are not located in a country
              that is subject to a US government embargo, or that has been
              designated by the US government as a &quot;terrorist
              supporting&quot; country and (ii) you are not listed on any US
              government list of prohibited or restricted parties;
            </li><li>
               you must
              comply with applicable third- party terms of agreement when using
              the App, e.g., if you have a VoIP application, then you must not be
              in violation of their wireless data service agreement when using the
              App; and (6) you acknowledge and agree that the App Distributors are
              third-party beneficiaries of the terms and conditions in this mobile
              application license contained in these Legal Terms, and that each
              App Distributor will have the right (and will be deemed to have
              accepted the right) to enforce the terms and conditions in this
              mobile application license contained in these Legal Terms against
              you as a third-party beneficiary thereof.
            </li>
            </ul>
          
          <h3 id="tab12" class="target-block">12. THIRD-PARTY WEBSITES AND CONTENT</h3>
          <p>
            The Services may contain (or you may be sent via the Site or App)
            links to other websites (&quot;Third-Party Websites&quot;) as well
            as articles, photographs, text, graphics, pictures, designs, music,
            sound, video, information, applications, software, and other content
            or items belonging to or originating from third parties
            (&quot;Third-Party Content&quot;). Such Third-Party Websites and
            Third-Party Content are not investigated, monitored, or checked for
            accuracy, appropriateness, or completeness by us, and we are not
            responsible for any Third-Party Websites accessed through the
            Services or any Third- Party Content posted on, available through,
            or installed from the Services, including the content, accuracy,
            offensiveness, opinions, reliability, privacy practices, or other
            policies of or contained in the Third-Party Websites or the
            Third-Party Content. Inclusion of, linking to, or permitting the use
            or installation of any Third-Party Websites or any Third-Party
            Content does not imply approval or endorsement thereof by us. If you
            decide to leave the Services and access the Third-Party Websites or
            to use or install any Third-Party Content, you do so at your own
            risk, and you should be aware these Legal Terms no longer govern.
            You should review the applicable terms and policies, including
            privacy and data gathering practices, of any website to which you
            navigate from the Services or relating to any applications you use
            or install from the Services. Any purchases you make through
            Third-Party Websites will be through other websites and from other
            companies, and we take no responsibility whatsoever in relation to
            such purchases which are exclusively between you and the applicable
            third party. You agree and acknowledge that we do not endorse the
            products or services offered on Third-Party Websites and you shall
            hold us blameless from any harm caused by your purchase of such
            products or services. Additionally, you shall hold us blameless from
            any losses sustained by you or harm caused to you relating to or
            resulting in any way from any Third-Party Content or any contact
            with Third-Party Websites.
          </p>
          <h3 id="tab13" class="target-block">13. SERVICES MANAGEMENT</h3>
          <p>
            We reserve the right, but not the obligation, to: (1) monitor the
            Services for violations of these Legal Terms; (2) take appropriate
            legal action against anyone who, in our sole discretion, violates
            the law or these Legal Terms, including without limitation,
            reporting such user to law enforcement authorities; (3) in our sole
            discretion and without limitation, refuse, restrict access to, limit
            the availability of, or disable (to the extent technologically
            feasible) any of your Contributions or any portion thereof, (4) in
            our sole discretion and without limitation, notice, or liability, to
            remove from the Services or otherwise disable all files and content
            that are excessive in size or are in any way burdensome to our
            systems; and (5) otherwise manage the Services in a manner designed
            to protect our rights and property and to facilitate the proper
            functioning of the Services.
          </p>
          <h3 id="tab14" class="target-block">14. PRIVACY POLICY</h3>
          <p>
            We care about data privacy and security. Please review our Privacy
            Policy:

            <a href="https://trackpal.co/privacy-policy"
              >https://trackpal.co/privacy-policy</a
            >.
          </p>
          <p>
            By using the Services, you agree to be bound by our Privacy Policy,
            which is incorporated into these Legal Terms. Please be advised the
            Services are hosted in the United States. If you access the Services
            from any other region of the world with laws or other requirements
            governing personal data collection, use, or disclosure that differ
            from applicable laws in the United States, then through your
            continued use of the Services, you are transferring your data to the
            United States, and you expressly consent to have your data
            transferred to and processed in the United States. Further, we do
            not knowingly accept, request, or solicit information from children
            or knowingly market to children. Therefore, in accordance with the
            U.S. Children's Online Privacy Protection Act, if we receive actual
            knowledge that anyone under the age of 13 has provided personal
            information to us without the requisite and verifiable parental
            consent, we will delete that information from the Services as
            quickly as is reasonably practical.
          </p>
          <h3 id="tab15" class="target-block">15. TERM AND TERMINATION</h3>
          <p>
            These Legal Terms shall remain in full force and effect while you
            use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE
            LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND
            WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES
            (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY
            REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF
            ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL
            TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR
            USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY
            CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING,
            IN OUR SOLE DISCRETION.
          </p>
          <p>
            If we terminate or suspend your account for any reason, you are
            prohibited from registering and creating a new account under your
            name, a fake or borrowed name, or the name of any third party, even
            if you may be acting on behalf of the third party. In addition to
            terminating or suspending your account, we reserve the right to take
            appropriate legal action, including without limitation pursuing
            civil, criminal, and injunctive redress.
          </p>
          <h3 id="tab16" class="target-block">16. MODIFICATIONS AND INTERRUPTIONS</h3>
          <p>
            We reserve the right to change, modify, or remove the contents of
            the Services at any time or for any reason at our sole discretion
            without notice. However, we have no obligation to update any
            information on our Services. We will not be liable to you or any
            third party for any modification, price change, suspension, or
            discontinuance of the Services. We cannot guarantee the Services
            will be available at all times. We may experience hardware,
            software, or other problems or need to perform maintenance related
            to the Services, resulting in interruptions, delays, or errors. We
            reserve the right to change, revise, update, suspend, discontinue,
            or otherwise modify the Services at any time or for any reason
            without notice to you. You agree that we have no liability
            whatsoever for any loss, damage, or inconvenience caused by your
            inability to access or use the Services during any downtime or
            discontinuance of the Services.
          </p>
          <p>
            Nothing in these Legal Terms will be construed to obligate us to
            maintain and support the Services or to supply any corrections,
            updates, or releases in connection therewith.
          </p>
          <h3 id="tab17" class="target-block">17. GOVERNING LAW</h3>
          <p>
            These Legal Terms and your use of the Services are governed by and
            construed in accordance with the laws of the State of New York
            applicable to agreements made and to be entirely performed within
            the State of New York, without regard to its conflict of law
            principles.
          </p>
          <h3 id="tab18" class="target-block">18. DISPUTE RESOLUTION</h3>
          <p><strong>Informal Negotiations</strong></p>
          <p>
            To expedite resolution and control the cost of any dispute,
            controversy, or claim related to these Legal Terms (each a
            &quot;Dispute&quot; and collectively, the &quot;Disputes&quot;)
            brought by either you or us (individually, a &quot;Party&quot; and
            collectively, the &quot;Parties&quot;), the Parties agree to first
            attempt to negotiate any Dispute (except those Disputes expressly
            provided below) informally for at least thirty (30) days before
            initiating arbitration. Such informal negotiations commence upon
            written notice from one Party to the other Party.<br />
            Binding Arbitration
          </p>
          <p>
            If the Parties are unable to resolve a Dispute through informal
            negotiations, the Dispute (except those Disputes expressly excluded
            below) will be finally and exclusively resolved by binding
            arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD
            HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The
            arbitration shall be commenced and conducted under the Commercial
            Arbitration Rules of the American Arbitration Association
            (&quot;AAA&quot;) and, where appropriate, the AAA's Supplementary
            Procedures for Consumer Related Disputes (&quot;AAA Consumer
            Rules&quot;), both of which are available at the American
            Arbitration Association (AAA) website. Your arbitration fees and
            your share of arbitrator compensation shall be governed by the AAA
            Consumer Rules and, where appropriate, limited by the AAA Consumer
            Rules. The arbitration may be conducted in person, through the
            submission of documents, by phone, or online. The arbitrator will
            make a decision in writing, but need not provide a statement of
            reasons unless requested by either Party. The arbitrator must follow
            applicable law, and any award may be challenged if the arbitrator
            fails to do so. Except where otherwise required by the applicable
            AAA rules or applicable law, the arbitration will take place in
            Suffolk County, New York. Except as otherwise provided herein, the
            Parties may litigate in court to compel arbitration, stay
            proceedings pending arbitration, or to confirm, modify, vacate, or
            enter judgment on the award entered by the arbitrator.
          </p>
          <p>
            If for any reason, a Dispute proceeds in court rather than
            arbitration, the Dispute shall be commenced or prosecuted in the
            state and federal courts located in Suffolk County, New York, and
            the Parties hereby consent to, and waive all defenses of lack of
            personal jurisdiction, and forum non conveniens with respect to
            venue and jurisdiction in such state and federal courts. Application
            of the United Nations Convention on Contracts for the International
            Sale of Goods and the Uniform Computer Information Transaction Act
            (UCITA) are excluded from these Legal Terms. If this provision is
            found to be illegal or unenforceable, then neither Party will elect
            to arbitrate any Dispute falling within that portion of this
            provision found to be illegal or unenforceable and such Dispute
            shall be decided by a court of competent jurisdiction within the
            courts listed for jurisdiction above, and the Parties agree to
            submit to the personal jurisdiction of that court.<br />
            Restrictions
          </p>
          <p>
            The Parties agree that any arbitration shall be limited to the
            Dispute between the Parties individually. To the full extent
            permitted by law, (a) no arbitration shall be joined with any other
            proceeding; (b) there is no right or authority for any Dispute to be
            arbitrated on a class-action basis or to utilize class action
            procedures; and (c) there is no right or authority for any Dispute
            to be brought in a purported representative capacity on behalf of
            the general public or any other persons. Exceptions to Informal
            Negotiations and Arbitration.
          </p>
          <p>
            The Parties agree that the following Disputes are not subject to the
            above provisions concerning informal negotiations binding
            arbitration: (a) any Disputes seeking to enforce or protect, or
            concerning the validity of, any of the intellectual property rights
            of a Party; (b) any Dispute related to, or arising from, allegations
            of theft, piracy, invasion of privacy, or unauthorized use; and (c)
            any claim for injunctive relief. If this provision is found to be
            illegal or unenforceable, then neither Party will elect to arbitrate
            any Dispute falling within that portion of this provision found to
            be illegal or unenforceable and such Dispute shall be decided by a
            court of competent jurisdiction within the courts listed for
            jurisdiction above, and the Parties agree to submit to the personal
            jurisdiction of that court.
          </p>
          <h3 id="tab19" class="target-block">19. CORRECTIONS</h3>
          <p>
            There may be information on the Services that contains typographical
            errors, inaccuracies, or omissions, including descriptions, pricing,
            availability, and various other information. We reserve the right to
            correct any errors, inaccuracies, or omissions and to change or
            update the information on the Services at any time, without prior
            notice.
          </p>
          <h3 id="tab20" class="target-block">20. DISCLAIMER</h3>
          <p>
            THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
            AGREE THAT PARTICULAR YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE
            RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
            WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND
            YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PURPOSE, AND
            NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
            ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF
            ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE
            WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY</p>
            <ul>
            <li>
              ERRORS,
              MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,
            </li><li>
              PERSONAL
              INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM
              YOUR ACCESS TO AND USE OF THE SERVICES,
            </li><li>
              ANY UNAUTHORIZED ACCESS
              TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
              INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN,
            </li><li>
               ANY
              INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES,
            </li>
            <li>
               ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
              TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR
            </li>
            <li>
               ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY
              LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
              CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
              SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
              RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
              THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY
              WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
              ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
              RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
              THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE
              OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT,
              YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
              APPROPRIATE.
            </li>
            </ul>
          
          <h3 id="tab21" class="target-block">21. LIMITATIONS OF LIABILITY</h3>
          <p>
            IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
            TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
            EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
            PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM
            YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE
            CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE
            WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL
            TIMES BE LIMITED TO THE LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO
            US OR $0.00 USD. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT
            ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR
            LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR
            ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU,
            AND YOU MAY HAVE ADDITIONAL RIGHTS.
          </p>
          <h3 id="tab22" class="target-block">22. INDEMNIFICATION</h3>
          <p>
            You agree to defend, indemnify, and hold us harmless, including our
            subsidiaries, affiliates, and all of our respective officers,
            agents, partners, and employees, from and against any loss, damage,
            liability, claim, or demand, including reasonable attorneys' fees
            and expenses, made by any third party due to or arising out of:</p>
            <ul>
            <li>
              
              your contributions;
            </li><li> use of the Services; </li><li>
               breach of these
              Legal Terms;
            </li><li>
               any breach of your representations and warranties
              set forth in these Legal Terms;
            </li><li>
               your violation of the rights of
              a third party, including but not limited to intellectual property
              rights; or
            </li><li>
               any overt harmful act toward any other user of the
              Services with whom you connected via the Services. Notwithstanding
              the foregoing, we reserve the right, at your expense, to assume the
              exclusive defense and control of any matter for which you are
              required to indemnify us, and you agree to cooperate, at your
              expense, with our defense of such efforts to notify you of any such
              claim, action, upon becoming aware of it.
            </li>
          </ul>
          <h3 id="tab23" class="target-block">23. USER DATA</h3>
          <p>
            We will maintain certain data that you or proceeding claims. We will
            use reasonable which is subject to this indemnification transmit to
            the Services for the purpose of managing the performance of the
            Services, as well as data relating to your use of the Services.
            Although we perform regular routine backups of data, you are solely
            responsible for all data that you transmit or that relates to any
            activity you have undertaken using the Services. You agree that we
            shall have no liability to you for any loss or corruption of any
            such data, and you hereby waive any right of action against us
            arising from any such loss or corruption of such data.
          </p>
          <h3 id="tab24" class="target-block">24. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h3>
          <p>
            Visiting the Services, sending us emails, and completing online
            forms constitute electronic communications. You consent to receive
            electronic communications, and you agree that all agreements,
            notices, disclosures, and other communications we provide to you
            electronically, via email and on the Services, satisfy any legal
            requirement that such communication be in writing. YOU HEREBY AGREE
            TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
            RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND
            RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE
            SERVICES You hereby waive any rights or requirements under any
            statutes, regulations, rules, ordinances, or other laws in any
            jurisdiction which require an original signature or delivery or
            retention of non- electronic records, or to payments or the granting
            of credits by any means other than electronic means.
          </p>
          <h3 id="tab25" class="target-block">25. CONTACT US</h3>
          <p>
            In order to resolve a complaint regarding the Services or to receive
            further information regarding use of the Services, please contact us
            at:
          </p>
          <p>
            Mail: TrackPal, 526 Melrose Street, Water Mill, 11976 New York Water
          </p>
          <p>Mill, NY United States</p>
          <p>Phone: <a href="tel:180707555321">(+1)080 707 555-321</a></p>
          <p>Email: <a href="mailto:info@trackpal.co">info@trackpal.co</a></p>
        </div>
      </div>
    </section>
    <!-- End Terms & Conditions Section -->
    <!-- Start Contact Info Section -->
    <!-- <section class="contact-info-wrapper bg-grey">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title">
              <h6 class="sub-title">Find Us</h6>
              <h2>Contact Info</h2>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="contact-info-content">
              <h5>USA Headquarter</h5>
              <p>
                304 NW St Homestead, Florida, Melrose Street, Water Mill, 76B
                Overlook Drive Chester, PA 19013, Flemingsburg USA.
              </p>
              <a href="tel:12345678">080 707 555-321</a>
              <a href="mailto:demo@example.com">demo@example.com</a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="contact-info-content">
              <h5>New York Office</h5>
              <p>
                1540 Pecks Ridge Tilton Rd Flemingsburg, Kentucky(KY), 4104188
                Fulton Street Blackwood, NJ 08012, London.
              </p>
              <a href="tel:12345678">080 707 555-321</a>
              <a href="mailto:demo@example.com">demo@example.com</a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="contact-info-content">
              <h5>Panama Office</h5>
              <p>
                103 Richard Ave Ashville, Ohio, Water Mill,3468 16th Hwy
                Pangburn, Arkansas(AR), Charolais Ashville, Virginia, Panama.
              </p>
              <a href="tel:12345678">080 707 555-321</a>
              <a href="mailto:demo@example.com">demo@example.com</a>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- End Contact Info Section -->

    <!-- Start Map Section -->
    <!-- <div class="map-area">
      <div class="map-content">
        <div class="map-canvas" id="contact-map"></div>
      </div>
    </div> -->
    <!-- End Map Section -->
    <!-- <Footer /> -->
  </section>
</template>

<script>
import { ref } from "vue";
// import Navbar02 from "../components/Navbar02.vue";
// import Footer from "../components/Footer.vue";
export default {
  components: {
    // Navbar02,
    // Footer,
  },
  setup() {
    const icons = ref([
      { name: "alternate_email", text: "by email" },
      { name: "local_phone", text: "by phone" },
      { name: "local_post_office", text: "by post" },
      { name: "local_fire_department", text: "by smoke signal" },
    ]);

    return { icons };
  },
  mounted() {
    //document.addEventListener("DOMContentLoaded", function () {
      // Get all the clickable links
      var links = document.querySelectorAll(".clickable");

      // Add click event listener to each link
      links.forEach(function (link) {
        link.addEventListener("click", function () {
          // Prevent the default action of the anchor tag
          //event.preventDefault();

          // Get the target block ID from the data-target attribute
          let targetBlockId = this.getAttribute("data-target");

          // Remove 'active' class from all target blocks
          var targetBlocks = document.querySelectorAll(".target-block");
          targetBlocks.forEach(function(block) {
              block.classList.remove('active');
          });
          

          // Add 'active' class to the target block associated with the clicked link
          var targetElement = document.getElementById(targetBlockId);
         
          targetElement.classList.add("active");
        });
      });
    //});
  },
};
</script>

<style>
.termsbg {
  background: url("../../public/img/termsConditions.jpg") no-repeat bottom
    center;
  background-size: contain;
  background-color: #820f87;
}
.contact ul {
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  max-width: 400px;
  margin: 60px auto;
}
.contact li {
  list-style-type: none;
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  line-height: 1.5em;
}
.target-block {
  /* Your CSS attributes for the targeted blocks */
  padding-top: inherit; /* Initially hide all blocks */
}

.target-block.active {
  padding-top: 70px; /* Show only the active (clicked) block */
}
</style>
