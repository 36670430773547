import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import UserProfile from "../views/UserProfile.vue";
import UserLogin from "../views/User_Login.vue";
import ContactUs from "../views/ContactUs.vue";
import TermsConditions from "../views/Terms-and-Conditions.vue";
import Terms_conditions from "../views/Terms_and_conditions.vue";
import PrivacyPolicy from "../views/Privacy-Policy.vue";
import DeletionPolicy from "../views/Deletion-Policy.vue";
import Deletion_policy from "../views/Deletion_policy.vue";
import Privacy_policy from "../views/Privacy_policy.vue";
import AboutUs from "../views/AboutUs.vue";
import About_us from "../views/About_us.vue";
//import ForgetPassword from '../views/ForgetPassword.vue'
import ForgetPassword from "../views/ForgetPassword.vue";
import PageNotfound from "../views/PageNotfound.vue";
//import { auth } from "@/firebase";
import store from "@/store";
//import store from '@/store'
//  import { toast } from 'vue3-toastify';

// const isUserLoggedin = (to, from, next) => {

//   Object.keys(store.state.currentUser==="").length > 0 ? next() : next('/');
//   if (localStorage.getItem("isLoggedIn")=="true") {
//     setTimeout(() => {
//       //Redirect code here
//       // e.g., this.$router.push('/new-page');
//       router.push('/')
//       toast.success("Success Notification !", {
//         position: toast.POSITION.TOP_RIGHT,
//         timeout: 5000,
//         duration: 5000,
//         closeOnClick: true,
//         pauseOnFocusLoss: true,
//         pauseOnHover: true,
//         draggable: true,
//         draggablePercent: 0.6,
//         showCloseButtonOnHover: false,
//         hideProgressBar: true,
//         closeButton: true,
//         icon: true,
//         rtl: false,
//         // autoClose: false,
//       });

//     }, 300);
//   }

//  }

async function authGuard(to, from, next) {
  let response = await store.dispatch('verify');
  if (response == true) {
    next();
  } else {
    await store.dispatch('logout')
    next({ name: "UserLogin" });
  }
}

function baseGuard(to, from, next) {
  next({ name: "Home" })
}

const routes = [
  {
    path: "/",
    name: "base",
    // component: Home,
    beforeEnter: baseGuard
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/user-profile",
    name: "UserProfile",
    component: UserProfile,
    beforeEnter: authGuard,
    meta: {
      requiresAuth: true,
    },
    // beforeEnter: isUserLoggedin

    // requiresAuth : true,
  },
  {
    path: "/user-login",
    name: "UserLogin",
    component: UserLogin,

    //  meta: {
    //   requiresAuth: true,
    // }
    //  beforeEnter: isUserLoggedin
  },
  {
    path: "/forget-password",
    name: "ForgetPassword",
    component: ForgetPassword,
    //  meta: {
    //   requiresAuth: true,
    // }
    //  beforeEnter: isUserLoggedin
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: ContactUs,
    //  meta: {
    //   requiresAuth: true,
    // }
  },
  {
    path: "/terms-and-conditions",
    name: "TermsConditions",
    component: TermsConditions,
    //  meta: {
    //   requiresAuth: true,
    // }
  },
  {
    path: "/terms_and_conditions",
    name: "Terms_conditions",
    component: Terms_conditions,
    //  meta: {
    //   requiresAuth: true,
    // }
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: AboutUs,
    //  meta: {
    //   requiresAuth: true,
    // }
  },
  {
    path: "/about_us",
    name: "About_us",
    component: About_us,
    //  meta: {
    //   requiresAuth: true,
    // }
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    //  meta: {
    //   requiresAuth: true,
    // }
  },
  {
    path: "/deletion-policy",
    name: "DeletionPolicy",
    component: DeletionPolicy,
    //  meta: {
    //   requiresAuth: true,
    // }
  },
  {
    path: "/deletion_policy",
    name: "Deletion_policy",
    component: Deletion_policy,
    //  meta: {
    //   requiresAuth: true,
    // }
  },
  {
    path: "/privacy_policy",
    name: "Privacy_policy",
    component: Privacy_policy,
    //  meta: {
    //   requiresAuth: true,
    // }
  },
  {
    path: "/:pathMatch(.*)*",

    component: PageNotfound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.path === "/user-login" && auth.currentUser) {
//     next("/user-profile");
//     return;
//   }
//   if (
//     to.matched.some((record) => record.meta.requiresAuth) &&
//     !auth.currentUser
//   ) {
//     next("/user-login");
//     return;
//   }

//   next();
// });

// router.beforeEach((to, from, next) => {
//   if (to.path === "/" && auth.currentUser) {
//     next("/user-profile");
//     return;
//   }
//   if (
//     to.matched.some((record) => record.meta.requiresAuth) &&
//     !auth.currentUser
//   ) {
//     next("/user-login");
//     return;
//   }

//   next();
// });

// router.beforeEach(async (to, from, next) => {
//   const user = await auth();

//   // Check if the route requires authentication
//   if (to.meta.requiresAuth && !user) {
//     // Redirect to the login page if not authenticated
//     next({ name: 'User_Login' });
//   } else if (to.name === 'User_Login' && user) {
//     // Redirect to the user profile page if already logged in
//     next({ name: 'UserProfile' });
//   } else {
//     next();
//   }
// });

export default router;
