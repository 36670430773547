<template>
  <section>
    <!-- Start Navbar Section -->
    <nav class="navbar navbar-expand-lg navbar-dark fixed-top bg-darkk">
      <div class="container">
        <div class="col-md-6">
          <!-- <a class="navbar-brand" href="#"> -->
          <div class="techvio-responsive-menu">
            <div class="logo">
              <!-- <a href="#" @click="$router.push('/')"> -->
              <a href="#" @click="pageRefresh">
                <img
                  src="../../public/img/logo.png"
                  class="showd"
                  alt="logo"
                  id="img01"
                />
                <img
                  src="../../public/img/logo-black.png"
                  class="hided"
                  alt="logo"
                  id="img02"
                />
              </a>
            </div>
          </div>
          <!--</a> -->
        </div>
        <div class="col-md-6">
          <div class="about-btn-boxx float-right">
            <a class="default-btn" @click="handleLoginLogoutt()"
              >{{ this.$store.state.btnText }}<span></span
            ></a>
            <!-- <a class="default-btn" v-else @click="logout"
              >{{ btnText }}<span></span
            ></a> -->
          </div>
        </div>
      </div>
    </nav>

    <!-- End Navbar Section -->
  </section>
</template>

<script>
import router from "@/router";

export default {
  name: "Navbar",
  data() {
    return {
      isLoggedIn: false, // Initial login status
    };
  },

  mounted() {
    const storedloggedin = localStorage.getItem("isLoggedIn");
    if (storedloggedin) {
      this.isLoggedIn = storedloggedin;
      console.log(this.isLoggedIn, "new value");

      //this.$store.state.btnText === "Logout";
      this.$store.dispatch("setlogout");
    }
  },
  methods: {
    handleLoginLogoutt() {
      window.addEventListener("DOMContentLoaded", () => {
        window.scrollTo(0, 0);
      });

      if (this.$store.state.btnText === "Logout") {
        window.scroll(0, 0);
        router.push("/user-login");
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        this.isLoggedIn = false;
        //this.$router.push("/user-login");
        this.$store.dispatch("logout");
      }
      window.scroll(0, 0);
      router.push("/user-login");
    },

    handleLoginLogout() {
      if (this.isLoggedIn) {
        console.log(this.isLoggedIn);
        // Perform logout actions
        router.push("/user-login");
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        this.isLoggedIn = false;
        //this.$store.dispatch("setlogout");

        // Redirect to login page if needed
      } else if (!this.isLoggedIn) {
        // Perform login actions
        //localStorage.setItem("isLoggedIn", "true");
        this.isLoggedIn = true;

        // Redirect to the desired page after login
        router.push("/");
      }
    },

    toggleView() {
      this.isLoggedIn = !this.isLoggedIn;
    },

    pageRefresh() {
      //this.$router.go(0);
      this.$router.push("/");
      // this.$router.go(0);
    },
  },
};
</script>

<style scoped>
.navbg {
  background: #320879 !important;
}
.default-btn {
  cursor: pointer;
}
</style>
