<template>
  <section>
    <!-- Start About Section -->
    <section class="about-area bg-grey section-padding">
      <div class="container">
        <div class="row d-flex align-items-center">
          <div class="col-lg-6 col-md-12">
            <div class="about-content">
              <h6 class="sub-title">Company Insights</h6>
              <h2>Providing Advanced and Trusted Tracking Solutions</h2>
              <p>
                TrackPal is not just an app; it's a reliable partner that brings
                a new level of connectivity. To enhance your connectivity and
                elevate your tracking experience, we ensure:
              </p>
              <div class="skills">
                <div class="skill-item">
                  <h6>Secure Access <em>100%</em></h6>
                  <div class="skill-progress">
                    <div class="progres" data-value="100%"></div>
                  </div>
                </div>
                <div class="skill-item">
                  <h6>Location Sharing <em>99.99%</em></h6>
                  <div class="skill-progress">
                    <div class="progres" data-value="99.99%"></div>
                  </div>
                </div>
                <div class="skill-item">
                  <h6>Offline Maps <em>95%</em></h6>
                  <div class="skill-progress">
                    <div class="progres" data-value="95%"></div>
                  </div>
                </div>
                <div class="skill-item">
                  <h6>Customer Support <em>100%</em></h6>
                  <div class="skill-progress">
                    <div class="progres" data-value="100%"></div>
                  </div>
                </div>
              </div>
              <div class="about-btn-box">
                <a class="default-btn" href="/about-us" @click="goabout"
                  >Learn More<span></span
                ></a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <transition appear @enter="enter" @before-enter="beforeEnter">
              <div class="about-image">
                <img src="/img/about002.png" alt="About image" />
                <div class="years-design">
                  <h2>23</h2>
                  <h5>Years Of Experience</h5>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </section>
    <!-- End About Section -->
  </section>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default {
  methods: {
    beforeEnter(el) {
      el.style.opacity = "0";
      el.style.transform = "translateX(-1450px)";
    },
    enter(el, done) {
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,

          toggleActions: "restart resume  reverse pause",
        },
        opacity: 1,
        x: 0,
        duration: 3,
        onComplete: done,
        delay: 0.4,
      });
    },
    goabout() {
      window.scroll(0, 0);
      this.$router.push("/about-us");
    },
  },
};
</script>

<style>
.about {
  max-width: 600px;
  margin: 20px auto;
}
</style>
