// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCLH2drDtMZ2A0DPcWH7NzMd9G9_FaCpGQ",
  authDomain: "track-pal-e4e07.firebaseapp.com",
  databaseURL: "https://track-pal-e4e07-default-rtdb.firebaseio.com",
  projectId: "track-pal-e4e07",
  storageBucket: "track-pal-e4e07.appspot.com",
  messagingSenderId: "1005053076444",
  appId: "1:1005053076444:web:77ee2049dab4d72e4be519",
  measurementId: "G-86CPQKR3X3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const auth = getAuth(app)
export { auth }