<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> |
    <router-link to="/contact">Contact</router-link>
  </div> -->
  <router-view />
</template>
<script>
// import { onBeforeMount } from "vue";
// import store from "vuex";

// export default {
//   setup() {
//     onBeforeMount(() => {
//       store.dispatch("fetchUser");
//     });
//   },
// };
</script>

<style>
</style>
