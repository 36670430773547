<template>
  <section>
    <!-- Start Counter Section -->
    <section class="counter-area section-padding">
      <div class="container">
        <!-- <h1>The easiest way</h1> -->
        <!-- <Vue3autocounter
          ref="counter"
          :startAmount="0"
          :endAmount="2021"
          :duration="3"
          prefix="$"
          suffix="USD"
          separator=","
          decimalSeparator="."
          :decimals="2"
          :autoinit="true"
          @finished="alert(`Counting finished!`)"
        /> -->
        <div class="row">
          <div class="col-lg-3 col-md-6 counter-item">
            <div class="single-counter">
              <div class="counter-contents">
                <h2>
                  <span class="counter-number">
                    <Vue3autocounter
                      ref="counter"
                      :startAmount="0"
                      :endAmount="10"
                      :duration="3"
                      :autoinit="this.trigerVal"
                    />
                  </span>
                  <span>+</span>
                </h2>
                <h3 class="counter-heading">Amazing Features</h3>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 counter-item">
            <div class="single-counter">
              <div class="counter-contents">
                <h2>
                  <span class="counter-number"
                    ><Vue3autocounter
                      ref="counter"
                      :startAmount="0"
                      :endAmount="50"
                      :duration="3"
                      :autoinit="this.trigerVal"
                  /></span>
                  <span>+</span>
                </h2>
                <h3 class="counter-heading">Working Employees</h3>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 counter-item">
            <div class="single-counter">
              <div class="counter-contents">
                <h2>
                  <span class="counter-number">
                    <Vue3autocounter
                      ref="counter"
                      :startAmount="0"
                      :endAmount="24"
                      :duration="3"
                      :autoinit="this.trigerVal"
                    />
                  </span>
                  <span></span>
                </h2>
                <h3 class="counter-heading">Hours Support</h3>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 counter-item">
            <div class="single-counter">
              <div class="counter-contents">
                <h2>
                  <span class="counter-number">
                    <Vue3autocounter
                      ref="counter"
                      :startAmount="0"
                      :endAmount="1000"
                      :duration="3"
                      :autoinit="this.trigerVal"
                    />
                  </span>
                  <span>+</span>
                </h2>
                <h3 class="counter-heading">Happy Customers</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Counter Section -->
  </section>
</template>

<script>
import Vue3autocounter from "vue3-autocounter";
export default {
  name: "CounterArea",
  components: {
    Vue3autocounter,
  },
  props: {
    trigerVal: Boolean,
  },
};
</script>

<style>
.about {
  max-width: 600px;
  margin: 20px auto;
}
</style>
