<template>
  <section>
    <!-- <Navbar02 /> -->
    <section>
      <!-- Start Page Title Section -->
      <!-- <div class="page-title-area item-bg1 termsbg">
        <div class="d-table">
          <div class="d-table-cell">
            <div class="container">
              <div class="page-title-content">
                <h2>Deletion Polciy</h2>
                <ul>
                  <li><a href="index-2.html">Home</a></li>
                  <li>Deletion Polciy</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- End Page Title Section -->
    </section>

    <!-- Start Terms & Conditions Section -->
    <section class="terms-section ptb-100">
      <div class="container">
        <div class="terms-conditions-info">
          <h3>Your User Account and Content</h3>
          <p>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            quae ab illo inventore veritatis et quasi architecto beatae vitae
            dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
            aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
            eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est,
            qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit,
            sed quia non numquam eius modi tempora incidunt ut labore et dolore
            magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis
            nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut
            aliquid ex ea commodi consequatur? Quis autem vel eum iure
            reprehenderit qui in ea voluptate velit esse quam nihil molestiae
            consequatur
          </p>

          <h3>Your Use of Our Service:</h3>
          <p>
            1. Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            quae ab illo inventore veritatis et quasi architecto beatae vitae
            dicta sunt explicabo.
          </p>
          <p>
            2. Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            quae ab illo inventore veritatis et quasi architecto beatae vitae
            dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
            aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
            eos qui ratione voluptatem sequi nesciunt.
          </p>
          <p>
            3. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit
            aut fugit, sed quia consequuntur magni dolores eos qui ratione
            voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem
            ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non
            numquam eius modi tempora incidunt ut labore et dolore magnam
            aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum
            exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid
            ex ea commodi consequatur
          </p>

          <h3>Reporting Copyright and Other IP Violations</h3>
          <p>
            <strong>1. Ownership:</strong> Sed ut perspiciatis unde omnis iste
            natus error sit voluptatem accusantium doloremque laudantium, totam
            rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
            architecto beatae vitae dicta sunt explicabo.
          </p>

          <p>
            <strong>2. Content Ownership:</strong> Sed ut perspiciatis unde
            omnis iste natus error sit voluptatem accusantium doloremque
            laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
            veritatis et quasi architecto beatae vitae dicta sunt explicabo.
            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
            fugit, sed quia consequuntur magni dolores eos qui ratione
            voluptatem sequi nesciunt.
          </p>

          <p>
            <strong>3. Rights in User Content</strong> Sed ut perspiciatis unde
            omnis iste natus error sit voluptatem accusantium doloremque
            laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
            veritatis et quasi architecto beatae vitae dicta sunt explicabo.
            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
            fugit, sed quia consequuntur magni dolores eos qui ratione
            voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem
            ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non
            numquam eius modi tempora incidunt ut labore et dolore magnam
            aliquam quaerat voluptatem.
          </p>
        </div>
      </div>
    </section>
    <!-- End Terms & Conditions Section -->
    <!-- Start Contact Info Section -->
    <!-- <section class="contact-info-wrapper bg-grey">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title">
              <h6 class="sub-title">Find Us</h6>
              <h2>Contact Info</h2>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="contact-info-content">
              <h5>USA Headquarter</h5>
              <p>
                304 NW St Homestead, Florida, Melrose Street, Water Mill, 76B
                Overlook Drive Chester, PA 19013, Flemingsburg USA.
              </p>
              <a href="tel:12345678">080 707 555-321</a>
              <a href="mailto:demo@example.com">demo@example.com</a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="contact-info-content">
              <h5>New York Office</h5>
              <p>
                1540 Pecks Ridge Tilton Rd Flemingsburg, Kentucky(KY), 4104188
                Fulton Street Blackwood, NJ 08012, London.
              </p>
              <a href="tel:12345678">080 707 555-321</a>
              <a href="mailto:demo@example.com">demo@example.com</a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="contact-info-content">
              <h5>Panama Office</h5>
              <p>
                103 Richard Ave Ashville, Ohio, Water Mill,3468 16th Hwy
                Pangburn, Arkansas(AR), Charolais Ashville, Virginia, Panama.
              </p>
              <a href="tel:12345678">080 707 555-321</a>
              <a href="mailto:demo@example.com">demo@example.com</a>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- End Contact Info Section -->

    <!-- Start Map Section -->
    <!-- <div class="map-area">
      <div class="map-content">
        <div class="map-canvas" id="contact-map"></div>
      </div>
    </div> -->
    <!-- End Map Section -->
    <!-- <Footer /> -->
  </section>
</template>

<script>
import { ref } from "vue";
// import Navbar02 from "../components/Navbar02.vue";
// import Footer from "../components/Footer.vue";
export default {
  components: {
    // Navbar02,
    // Footer,
  },
  setup() {
    const icons = ref([
      { name: "alternate_email", text: "by email" },
      { name: "local_phone", text: "by phone" },
      { name: "local_post_office", text: "by post" },
      { name: "local_fire_department", text: "by smoke signal" },
    ]);

    return { icons };
  },
};
</script>

<style>
.termsbg {
  background: url("../../public/img/termsConditions.jpg") no-repeat bottom
    center;
  background-size: contain;
  background-color: #820f87;
}
.contact ul {
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  max-width: 400px;
  margin: 60px auto;
}
.contact li {
  list-style-type: none;
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  line-height: 1.5em;
}
</style>
