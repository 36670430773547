<template>
  <section class="section-padding">
    <!-- Start Page Title Section -->
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-title">
            <h6 class="sub-title">Ask Our Team</h6>
            <h2>Frequently Ask Question</h2>
          </div>
        </div>
      </div>
    </div>
    <!-- End Page Title Section -->

    <!-- Start Faq Section -->
    <section class="faq-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <div class="faq-accordion first-faq-box">
              <ul class="accordion">
                <transition-group appear @enter="enter">
                  <li
                    class="accordion-item"
                    v-for="(faq, index) in faqs"
                    :key="index"
                    :data-index="index"                    
                  >
                    <a
                      class="accordion-title"
                      :class="index == 0 ? 'active' : ''"
                      href="javascript:void(0)"
                    >
                      <i class="fa fa-plus"></i> {{ faq.question }}</a
                    >
                    <p
                      class="accordion-content"
                      :class="index == 0 ? 'show' : ''"
                    >
                      <!-- {{ faq.answer }} -->
                      <div v-html="faq.answer"></div>
                    </p>
                  </li>
                </transition-group>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Faq Section -->
  </section>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "FAQ",
  data() {
    return {
      faqs: [
        {
          question: "Can I track multiple devices from my account?",
          answer:
            "TrackPal is designed to provide users with flexibility and convenience. You can effortlessly track multiple devices from a single account, making it a versatile solution for families, friends, businesses, or anyone with multiple devices. This feature allows you to keep an eye on the real-time location of various devices, ensuring you stay connected and informed.",
        },
        {
          question: "How does TrackPal protect my privacy?",
          answer: `<p></p>We prioritize and value your privacy. Our commitment to protecting your personal information is reflected in several key privacy protection measures, such as:</p>
          <div class="faqul">
          <ul>
            <li>We employ robust encryption protocols to secure the transmission of data, ensuring that your location information remains confidential.</li>
            <li>Providing users with customizable privacy settings, we allow you to control who has access to your location data and when.</li>
            <li>The TrackPal team adheres to strict privacy policies, ensuring transparent and ethical handling of user information. </li>
            <li>Our team continuously updates security features to counter emerging threats, guaranteeing a secure environment for your data. </li>
            </ul> 
            <div class="clear"></div>           
            </div>
            
<p>At TrackPal, your privacy is our top priority, and we take every precaution to maintain the confidentiality and integrity of your personal information.,</p>`
        },
        {
          question:
            "How does the TrackPal GPS tracker work?",
          answer:
            "Once the app is installed, it continuously collects location data using GPS signals. This information is securely transmitted to our servers, allowing users to access real-time location updates through their accounts. The app is designed with user customization in mind, enabling personalized tracking preferences, geofencing alerts, and instant notifications. Whether it's keeping an eye on a friend or a group of people, TrackPal provides a seamless and efficient solution, ensuring accurate and reliable location tracking for various purposes.",
        },
        {
          question: "My location data is protected by my credentials. How can I share my location?",
          answer:
            `<p>Your location data is indeed protected by your credentials, guaranteeing privacy and security. Our app offers a secure and user-friendly mechanism to share your location selectively. You have the flexibility to create shareable links or provide temporary access to specific individuals.</p>
<p>By sending these invitations, recipients can view your real-time location for the selected period without compromising your overall account security. It's a controlled and transparent process, permitting you to share your whereabouts with trusted contacts while maintaining the integrity of your login credentials.</p>
<p>TrackPal prioritizes user privacy, and our sharing features are designed to provide a balance between connectivity and protection.</p>` 
,
        },
        {
          question: "Is this app available in a free version?",
          answer:
          "TrackPal offers a free version, allowing users to access fundamental tracking features without any cost. This app is designed to meet a variety of tracking requirements while ensuring a seamless and secure user experience.",
        },
        
      ],
    };
  },
  mounted() {
    $(function () {
      jQuery(".accordion")
        .find(".accordion-title")
        .on("click", function () {
          // Adds Active Class
          jQuery(this).toggleClass("active");
          // Expand or Collapse This Panel
          jQuery(this).next().slideToggle("slow");
          // Hide The Other Panels
          jQuery(".accordion-content").not(jQuery(this).next()).slideUp("slow");
          // Removes Active Class From Other Titles
          jQuery(".accordion-title").not(jQuery(this)).removeClass("active");
        });
    });
  },
  methods: {
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = "translateY(100px)";
    },
    enter(el, done) {
      gsap.from(el, {
        opacity: 1,
        scrollTrigger: {
          opacity: 0,
          y: 100,
          toggleActions: "restart pause reverse play",
        },
        y: 0,
        duration: 0.8,
        onComplete: done,
        delay: el.dataset.index * 0.2,
        stagger: 1,
      });
    },
  },
};
</script>

<style scoped>
/* .section-title {
  margin: 0;
} */
.about-btn-box {
  display: inline-block;
  margin-top: 38px;
}
.clear{clear:both;}
.faqul {margin-bottom: 25px;display: block;}
.faqul ul{margin: 0; padding: 0; display: block;}
.faqul li{display: block; list-style-type: none; padding-left: 25px; margin-bottom: 12px; line-height: 24px;}
</style>
