<template>
  <section>
    <Navbar02 />
    <section>
      <!-- Start Page Title Section -->
      <div class="page-title-area item-bg1 delectionsbg">
        <div class="d-table">
          <div class="d-table-cell">
            <div class="container">
              <div class="page-title-content">
                <h2>Deletion Polciy</h2>
                <ul>
                  <li><a href="index-2.html">Home</a></li>
                  <li>Deletion Polciy</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Page Title Section -->
    </section>

    <!-- Start Terms & Conditions Section -->
    <section class="terms-section ptb-100">
      <div class="container">
        <div class="terms-conditions-info">
          <h3>INTRODUCTION:</h3>
          <p>
            This App Deletion Policy (&quot;Policy&quot;) outlines the
            procedures and guidelines for users to delete their accounts and
            associated data from TrackPal (&quot;App&quot;), in compliance with
            Apple's App Store and Google Play guidelines. By using the App,
            users agree to abide by this Policy.
          </p>
          <h3>ACCOUNT DELETION PROCESS:</h3>
          <p>
            Users who wish to delete their accounts and data from the App can
            follow these steps:
          </p>
          <ul>
            <li>
              Open the App and navigate to the &quot;Settings&quot; or
              &quot;Account&quot; section.
            </li>
            <li>Look for the &quot;Delete Account&quot;.</li>
            <li>
              Confirm their intention to delete the account when prompted.
            </li>
          </ul>
          <p>
            They can also delete their account using our web portal by following
            these steps:
          </p>
          <ul>
            <li>
              Login to their TrackPal Account and go to &ldquo;Setting&rdquo; or
              &ldquo;Account&rdquo; section.
            </li>
            <li>Look for the &ldquo;Delete Account&rdquo;.</li>
            <li>
              Confirm their intention to delete the account when prompted.
            </li>
          </ul>

          <h3>DATA DELETION:</h3>
          <p>
            Upon confirming the account deletion, the following data associated
            with the user's account will be permanently deleted:
          </p>
          <ul>
            <li>
              Personal information provided during account registration, such as
              name, email address, and profile picture.
            </li>
          </ul>
          <h3>RETENTION OF NON-PERSONAL DATA:</h3>
          <p>
            Some non-personal and non-identifiable data may be retained for
            analytical purposes, such as improving the App's performance and
            user experience. This data will be anonymized and used solely for
            statistical analysis.
          </p>

          <h3>COMMUNICATION:</h3>
          <p>
            Upon initiating the account deletion process, users may receive a
            confirmation email notifying you of the deletion request.
          </p>
          <h3>DATA SECURITY:</h3>
          <p>
            We take data security seriously and employ industry-standard
            measures to safeguard user data. Once an account deletion is
            confirmed, data removal will be irreversible, and reasonable
            precautions will be taken to prevent unauthorized access to deleted
            information.
          </p>
          <p>
            For any inquiries, concerns, or requests related to account deletion
            or data privacy, users can contact our support team at
            <a href="mailto:info@trackpal.com">info@trackpal.com</a>
          </p>
          <p>
            Policy changes: This Policy may be updated from time to time to
            reflect changes in our practices or regulatory requirements. Users
            will be notified of any significant changes in the Policy.
          </p>
        </div>
      </div>
    </section>
    <!-- End Terms & Conditions Section -->
    <!-- Start Contact Info Section -->
    <!-- <section class="contact-info-wrapper bg-grey">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title">
              <h6 class="sub-title">Find Us</h6>
              <h2>Contact Info</h2>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="contact-info-content">
              <h5>USA Headquarter</h5>
              <p>
                304 NW St Homestead, Florida, Melrose Street, Water Mill, 76B
                Overlook Drive Chester, PA 19013, Flemingsburg USA.
              </p>
              <a href="tel:12345678">080 707 555-321</a>
              <a href="mailto:demo@example.com">demo@example.com</a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="contact-info-content">
              <h5>New York Office</h5>
              <p>
                1540 Pecks Ridge Tilton Rd Flemingsburg, Kentucky(KY), 4104188
                Fulton Street Blackwood, NJ 08012, London.
              </p>
              <a href="tel:12345678">080 707 555-321</a>
              <a href="mailto:demo@example.com">demo@example.com</a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="contact-info-content">
              <h5>Panama Office</h5>
              <p>
                103 Richard Ave Ashville, Ohio, Water Mill,3468 16th Hwy
                Pangburn, Arkansas(AR), Charolais Ashville, Virginia, Panama.
              </p>
              <a href="tel:12345678">080 707 555-321</a>
              <a href="mailto:demo@example.com">demo@example.com</a>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- End Contact Info Section -->

    <!-- Start Map Section -->
    <!-- <div class="map-area">
      <div class="map-content">
        <div class="map-canvas" id="contact-map"></div>
      </div>
    </div> -->
    <!-- End Map Section -->
    <Footer />
  </section>
</template>

<script>
import { ref } from "vue";
import Navbar02 from "../components/Navbar02.vue";
import Footer from "../components/Footer.vue";
export default {
  components: {
    Navbar02,
    Footer,
  },
  setup() {
    const icons = ref([
      { name: "alternate_email", text: "by email" },
      { name: "local_phone", text: "by phone" },
      { name: "local_post_office", text: "by post" },
      { name: "local_fire_department", text: "by smoke signal" },
    ]);

    return { icons };
  },
};
</script>

<style>
.delectionsbg {
  background: url("../../public/img/deletionbg.jpg") no-repeat bottom center;
  background-size: contain;
  background-color: #820f87;
}
.contact ul {
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  max-width: 400px;
  margin: 60px auto;
}
.contact li {
  list-style-type: none;
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  line-height: 1.5em;
}
</style>
