<template>
  <section>
    <!-- Start Hire Section -->
    <section class="hire-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 offset-lg-2 col-md-12">
            <div class="hire-content">
              <!-- <h6 class="sub-title">Want to work with us?</h6> -->
              <h2>Stay Connected, Stay Secure – Track with TrackPal</h2>

              <div class="hire-btn">
                <a class="default-btn-one" href="/contact-us" @click="gocontact"
                  >Get The App<span></span
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Hire Section -->
  </section>
</template>

<script>
export default {
  setup() {},

  methods: {
    gocontact() {
      window.scroll(0, 0);
      this.$router.push("/contact-us");
    },
  },
};
</script>

<style></style>
