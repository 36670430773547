<template>
  <section>
    <!-- Start Home Section -->
    <div class="home-area">
      <div class="d-tablee">
        <div class="d-table-celll">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6 col-md-12">
                <div class="main-banner-content">
                  <h1>
                    Effortlessly Monitor and Stay Connected with Your Loved Ones
                  </h1>
                  <p>
                    The ultimate travel companion! TrackPal is a cutting-edge
                    tracking app designed to keep you connected with your loved
                    ones on their journeys. With real-time updates and precise
                    location tracking, the app transforms the way you connect
                    during travels, providing peace of mind and enhancing the
                    joy of shared experiences.
                  </p>
                  <div class="banner-btn">
                    <!-- <a class="default-btn-one" href="services.html"
                      >Our Service <span></span
                    ></a> -->
                    <a
                      class="default-btn-two"
                      href="/contact-us"
                      @click="contactus"
                      >Lets Connect<span></span
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12">
                <div class="banner-image">
                  <div
                    ref="dotlottieplayer"
                    class="banner-lottie"
                    src="./img/lottie/banner.json"
                    background="transparent"
                    speed="1.5"
                    style="width: 600px; height: 600px"
                    loop
                    autoplay
                  ></div>
                  <div
                    ref="dotlottieplayer02"
                    class="banner-lottie-bg"
                    src="./img/lottie/bg.json"
                    background="transparent"
                    speed="1.5"
                    style="width: 600px; height: 600px"
                    loop
                    autoplay
                  ></div>
                  <!-- <img
                    src="../../public/img/headernew-img001.png"
                    alt="image"
                  /> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="creative-shape">
        <img src="../../public/img/home-bottom-shape.png" alt="svg shape" />
      </div>
    </div>
    <!-- End Home Section -->
  </section>
</template>

<script>
import lottie from "lottie-web";
export default {
  name: "Header",
  mounted() {
    const animationData = require("../../public/img/lottie/banner.json"); // Replace with your animation data file
    const options = {
      container: this.$refs.dotlottieplayer,
      animationData: animationData,
      loop: true,
      autoplay: true,
    };
    this.lottieInstance = lottie.loadAnimation(options);

    const animationData02 = require("../../public/img/lottie/bg.json"); // Replace with your animation data file
    const options02 = {
      container: this.$refs.dotlottieplayer02,
      animationData: animationData02,
      loop: true,
      autoplay: true,
    };
    this.lottieInstance = lottie.loadAnimation(options02);
  },
  beforeUnmount() {
    // Destroy the Lottie animation instance to clean up resources
    if (this.lottieInstance) {
      this.lottieInstance.destroy();
    }
  },
  methods: {
    contactus() {
      window.scroll(0, 0);
      this.$router.push("/contact-us");
    },
  },
};
</script>

<style>
/* .bg-darkk {
  background: #9a2583 !important;
  padding: 16px 0;
} */
.bg-darkk {
  background: #b12888 !important;
  padding: 16px 0;
}
.home-area {
  height: 940px;
  padding-top: 85px;
}
.home-area .banner-image {
  position: relative;
}
.banner-lottie {
  position: relative;
  bottom: 60px;
  z-index: 1;
}
.banner-lottie-bg {
  position: absolute;
  top: 0px;
  z-index: 0;
}

@media only screen and (max-width: 991px) {
  .banner-image .banner-lottie {
    width: 450px !important;
    height: 450px !important;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .banner-image .banner-lottie-bg {
    width: 450px !important;
    height: 450px !important;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

@media only screen and (max-width: 600px) {
  .banner-image .banner-lottie {
    width: 300px !important;
    height: 300px !important;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .banner-image .banner-lottie-bg {
    width: 300px !important;
    height: 300px !important;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
</style>
