<template>
  <section>
    <!-- Start Footer & Subscribe Section -->
    <section class="footer-subscribe-wrapper">
      <!-- Start Subscribe Section -->
      <div class="subscribe-area">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
              <div class="subscribe-content">
                <h2>Sign Up Our Newsletter</h2>
                <p>
                  We Offer An Informative Monthly Technology Newsletter - Check
                  It Out.
                </p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <form class="newsletter-form">
                <input
                  type="email"
                  class="input-newsletter"
                  name="email"
                  placeholder="Enter Your Email"
                  required
                  autocomplete="off"
                />
                <button type="submit">Subscribe Now</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- End Subscribe Section -->
      <!-- Start Footer Section -->
      <div class="footer-area ptb-100">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-footer-widget">
                <a class="footer-logo" href="#">
                  <img
                    src="../../public/img/logo.png"
                    class="white-logo"
                    alt="logo"
                    width="50%"
                  />
                </a>
                <p>
                  TrackPal is more than a travel app; it’s your ultimate travel
                  companion. We pride ourselves on being a ground-breaking
                  tracking app that redefines connections during journeys. Our
                  mission is to elevate travel experiences by providing
                  unparalleled peace of mind and strengthening the bonds ...
                </p>
                <ul class="footer-social">
                  <li>
                    <a href="#"> <i class="fab fa-facebook-f"></i></a>
                  </li>
                  <li>
                    <a href="#"> <i class="fab fa-twitter"></i></a>
                  </li>
                  <li>
                    <a href="#"> <i class="fab fa-youtube"></i></a>
                  </li>
                  <li>
                    <a href="#"> <i class="fab fa-linkedin"></i></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
              <div class="single-footer-widget">
                <div class="footer-heading">
                  <h3>Main Links</h3>
                </div>
                <ul class="footer-quick-links">
                  <li><a href="#" @click="callHome">Home</a></li>
                  <li><a href="#" @click="callContact">Contact Us</a></li>
                  <li><a href="#" @click="callAboutUs">About Us</a></li>
                </ul>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
              <div class="single-footer-widget">
                <div class="footer-heading">
                  <h3>Useful Links</h3>
                </div>
                <ul class="footer-quick-links">
                  <li><a href="#" @click="callTerms">Terms & Conditions</a></li>
                  <li><a href="#" @click="callPpolicy">Privacy Policy</a></li>
                  <li><a href="#" @click="callDeletion">Deletion Policy</a></li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-footer-widget">
                <div class="footer-heading">
                  <h3>Contact Info</h3>
                </div>
                <div class="footer-info-contact">
                  <i class="flaticon-phone-call"></i>
                  <h3>Phone</h3>
                  <span><a href="tel:+7187919108">718-791-9108</a></span>
                </div>
                <div class="footer-info-contact">
                  <i class="flaticon-envelope"></i>
                  <h3>Email</h3>
                  <span
                    ><a href="mailto:wwwstanman2@aol.com"
                      >wwwstanman2@aol.com</a
                    ></span
                  >
                </div>
                <div class="footer-info-contact">
                  <i class="flaticon-placeholder"></i>
                  <h3>Address</h3>
                  <span
                    >20 East Grand Street, Unit JMount Vernon, NY 10552</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Footer Section -->
    </section>
    <!-- End Footer & Subscribe Section -->

    <!-- Start Copy Right Section -->
    <div class="copyright-area">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6">
            <p>
              <i class="far fa-copyright"></i> {{ cuYear }} TrackPal - All
              Rights Reserved.
            </p>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="madeby">
              Made with ❤️ by <a
                href="https://codment.com/"
                target="_blank"
                rel="noopener"
                >Codment</a
              >.
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Copy Right Section -->

    <!-- Start Go Top Section -->
    <div class="go-top">
      <i class="fas fa-chevron-up"></i>
      <i class="fas fa-chevron-up"></i>
    </div>
    <!-- End Go Top Section -->
  </section>
</template>

<script>
export default {
  setup() {},
  data() {
    return {
      cuYear: "",
    };
  },
  mounted() {
    jQuery(function () {
      // Scroll Event
      jQuery(window).on("scroll", function () {
        var scrolled = jQuery(window).scrollTop();
        if (scrolled > 600) jQuery(".go-top").addClass("active");
        if (scrolled < 600) jQuery(".go-top").removeClass("active");
      });
      // Click Event
      jQuery(".go-top").on("click", function () {
        jQuery("html, body").animate({ scrollTop: "0" }, 100);
      });
    });
    this.cuYear = new Date().getFullYear();
  },

  methods: {
    callHome() {
      window.scroll(0, 0);
      this.$router.push("/");
    },
    callContact() {
      window.scroll(0, 0);
      this.$router.push("/contact-us");
    },
    callPpolicy() {
      window.scroll(0, 0);
      this.$router.push("/privacy-policy");
    },
    callTerms() {
      window.scroll(0, 0);
      this.$router.push("/terms-and-conditions");
    },
    callAboutUs() {
      window.scroll(0, 0);
      this.$router.push("/about-us");
    },
    callDeletion() {
      window.scroll(0, 0);
      this.$router.push("/deletion-policy");
    },
  },
};
</script>

<style>
.about {
  max-width: 600px;
  margin: 20px auto;
}
.copyright-area .madeby {
  color: #fff;
  float: right;
}
.copyright-area .madeby a {
  color: #ba1382;
}
.copyright-area .madeby a:hover {
  color: #fff;
}
</style>
