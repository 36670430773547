<template>
  <section>
    sdfsde
    <Navbar02 />
    <section>
      <!-- Start Page Title Section -->
      <div class="page-title-area">
        <div class="d-table">
          <div class="d-table-cell">
            <div class="container">
              <div class="page-title-content">
                <h2>User Profile</h2>
                <ul>
                  <li><a href="#">Home</a></li>
                  <li>User Profile</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Page Title Section -->
    </section>
    <section>
      <div class="contact-section section-padding">
        <div class="container">
          <div class="section-title">
            <h6 class="sub-title">User's Info</h6>
            <h2>Account Details</h2>
          </div>
          <!-- <div class="row gutters">
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
              <div class="card h-100">
                <div class="card-body">
                  <div class="account-settings">
                    <div class="user-profile">
                      <div class="user-avatar">
                        <img
                          src="https://bootdey.com/img/Content/avatar/avatar7.png"
                          alt="Maxwell Admin"
                        />
                      </div>
                      <h5 class="user-name">Yuki Hayashi</h5>
                      <h6 class="user-email">yuki@Maxwell.com</h6>
                    </div>
                    <div class="about">
                      <h5>About</h5>
                      <p>
                        I'm Yuki. Full Stack Designer I enjoy creating
                        user-centric, delightful and human experiences.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
              <div class="card h-100">
                <div class="card-body">
                  <div class="row gutters">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <h6 class="mb-2 text-primary">Personal Details</h6>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="form-group">
                        <label for="fullName">Full Name</label>
                        <input
                          type="text"
                          class="form-control"
                          id="fullName"
                          placeholder="Enter full name"
                        />
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="form-group">
                        <label for="eMail">Email</label>
                        <input
                          type="email"
                          class="form-control"
                          id="eMail"
                          placeholder="Enter email ID"
                        />
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="form-group">
                        <label for="phone">Phone</label>
                        <input
                          type="text"
                          class="form-control"
                          id="phone"
                          placeholder="Enter phone number"
                        />
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="form-group">
                        <label for="website">Website URL</label>
                        <input
                          type="url"
                          class="form-control"
                          id="website"
                          placeholder="Website url"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row gutters">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <h6 class="mt-3 mb-2 text-primary">Address</h6>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="form-group">
                        <label for="Street">Street</label>
                        <input
                          type="name"
                          class="form-control"
                          id="Street"
                          placeholder="Enter Street"
                        />
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="form-group">
                        <label for="ciTy">City</label>
                        <input
                          type="name"
                          class="form-control"
                          id="ciTy"
                          placeholder="Enter City"
                        />
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="form-group">
                        <label for="sTate">State</label>
                        <input
                          type="text"
                          class="form-control"
                          id="sTate"
                          placeholder="Enter State"
                        />
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="form-group">
                        <label for="zIp">Zip Code</label>
                        <input
                          type="text"
                          class="form-control"
                          id="zIp"
                          placeholder="Zip Code"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row gutters">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div class="text-right">
                        <button
                          type="button"
                          id="submit"
                          name="submit"
                          class="btn btn-secondary"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          id="submit"
                          name="submit"
                          class="btn btn-primary"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="row">
            <div class="col-xl-4 order-xl-1 mb-5 mb-xl-0">
              <div class="card card-profile shadow">
                <div class="row justify-content-center">
                  <div class="col-lg-12 order-lg-12">
                    <div class="card-profile-image">
                      <a href="#">
                        <img :src="userImage" class="rounded-circle" />
                      </a>
                    </div>
                  </div>
                </div>
                <!-- <div
                  class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"
                >
                  <div class="d-flex justify-content-between">
                    <a href="#" class="btn btn-sm btn-info mr-4">Connect</a>
                    <a href="#" class="btn btn-sm btn-default float-right"
                      >Message</a
                    >
                  </div>
                </div> -->
                <div class="card-body pt-0 pt-md-4 pb-4">
                  <div class="text-center">
                    <h3>
                      {{ userName }}<span class="font-weight-light"></span>
                    </h3>
                    <!-- <div class="h5 font-weight-300">
                      <i class="ni location_pin mr-2"></i>Bucharest, Romania
                    </div>
                    <div class="h5 mt-4">
                      <i class="ni business_briefcase-24 mr-2"></i>Solution
                      Manager - Creative Tim Officer
                    </div>
                    <div>
                      <i class="ni education_hat mr-2"></i>University of
                      Computer Science
                    </div> -->
                    <!-- <hr class="my-4" /> -->

                    <!-- <div class="row">
                      <div class="col-6">
                        <button
                          class="default-btn"
                          id="show-modal"
                          @click="openModal"
                        >
                          Edit <i class="fa fa-pencil"></i><span></span>
                        </button>
                      </div>
                      <div class="col-6">
                        <button class="default-btn-two" @click="deletenow()">
                          Delete <i class="fa fa-trash"></i><span></span>
                        </button>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-8 order-xl-1 userInfo">
              <!-- Start Contact Section -->

              <!-- <div class="col-xl-8 mt-0 pt-0">
                  <div class="section-title">
                    <h6 class="sub-title">User's Info</h6>
                    <h2>Account Details</h2>
                  </div>
                </div> -->

              <div class="lotiibox">
                <div
                  ref="dotlottieplayer"
                  src="./img/lottie/profile.json"
                  background="transparent"
                  speed="1"
                  style="width: 200px; height: 200px"
                  loop
                  autoplay
                ></div>
              </div>

              <div class="row align-items-center">
                <!-- <div class="col-lg-10 offset-lg-1"> -->
                <div class="col-lg-10">
                  <div class="contact-form">
                    <p class="form-message"></p>
                    <div class="card card-profile shadow infoboxx">
                      <div class="row mb-4">
                        <div class="col-md-4 infolabel">Name:</div>
                        <div class="col-md-8 infodes">{{ userName }}</div>
                      </div>
                      <!-- <div class="row mb-4">
                        <div class="col-md-4 infolabel">Date of Birth:</div>
                        <div class="col-md-8 infodes">January 01, 2004</div>
                      </div> -->
                      <!-- <div class="row mb-4">
                        <div class="col-md-4 infolabel">Address:</div>
                        <div class="col-md-8 infodes">
                          San Francisco CA 97987 USA
                        </div>
                      </div> -->
                      <!-- <div class="row mb-4">
                        <div class="col-md-4 infolabel">City:</div>
                        <div class="col-md-8 infodes">California</div>
                      </div> -->
                      <!-- <div class="row mb-4">
                        <div class="col-md-4 infolabel">State:</div>
                        <div class="col-md-8 infodes">San Francisco</div>
                      </div> -->
                      <!-- <div class="row mb-4">
                        <div class="col-md-4 infolabel">Zip Code:</div>
                        <div class="col-md-8 infodes">1000</div>
                      </div> -->

                      <div class="row mb-4">
                        <div class="col-md-4 infolabel">Email Address:</div>
                        <div class="col-md-8 infodes">{{ userEmail }}</div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-md-4 infolabel">Phone Number:</div>
                        <div class="col-md-8 infodes">{{ userPhone }}</div>
                      </div>
                      <!-- <div class="row mb-4">
                        <div class="col-md-4 infolabel">Current Location:</div>
                        <div class="col-md-8 infodes">
                          San Francisco CA 97987 USA
                        </div>
                      </div> -->
                      <hr />
                      <div class="row mt-2">
                        <div class="col-6">
                          <button
                            class="default-btn"
                            id="show-modal"
                            @click="openModal"
                          >
                            Edit <i class="fa fa-pencil"></i><span></span>
                          </button>
                        </div>
                        <div class="col-6">
                          <button class="default-btn-two" @click="deletenow()">
                            Delete <i class="fa fa-trash"></i><span></span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- <form
                      id="contact-form"
                      class="contact-form form"
                      action="https://cutesolution.com/html/techvio/phpmails.php"
                      method="POST"
                    >
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <input
                              type="text"
                              name="name"
                              id="name"
                              class="form-control"
                              required
                              placeholder="Your Name"
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <input
                              type="email"
                              name="email"
                              id="email"
                              class="form-control"
                              required
                              placeholder="Your Email"
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <input
                              type="text"
                              name="phone"
                              id="phone"
                              required
                              class="form-control"
                              placeholder="Your Phone"
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <input
                              type="text"
                              name="subject"
                              id="subject"
                              class="form-control"
                              required
                              placeholder="Your Subject"
                            />
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                          <div class="form-group">
                            <textarea
                              name="message"
                              class="form-control"
                              id="message"
                              cols="30"
                              rows="6"
                              required
                              placeholder="Your Message"
                            ></textarea>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                          <button type="submit" class="default-btn submit-btn">
                            Send Message <span></span>
                          </button>
                        </div>
                      </div>
                    </form> -->
                  </div>
                </div>
              </div>

              <!-- End Contact Section -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <section ref="triggerSection">
      <CounterArea :trigerVal="trigerVal" v-if="trigshow" />
    </section>
    <FAQ /> -->
    <Footer />
    <ModalProfile
      :propValue="isModalOpen"
      @pparentCloseModal="closeModal"
      @userDate="getUser"
    />
  </section>
</template>

<script>
import Navbar02 from "./../components/Navbar02.vue";
import Footer from "./../components/Footer.vue";
// import CounterArea from "./../components/CounterArea.vue";
// import FAQ from "./../components/FAQ.vue";
import ModalProfile from "./../components/userProfileModal.vue";
import lottie from "lottie-web";
import { mapActions, mapGetters } from "vuex";

import gsap from "gsap";
export default {
  name: "UserProfile",
  components: {
    Navbar02,
    Footer,
    // CounterArea,
    // FAQ,
    ModalProfile,
  },

  // setup() {
  //   const icons = ref([
  //     { name: "alternate_email", text: "by email" },
  //     { name: "local_phone", text: "by phone" },
  //     { name: "local_post_office", text: "by post" },
  //     { name: "local_fire_department", text: "by smoke signal" },
  //   ]);

  //   return { icons };
  // },
  data() {
    return {
      trigerVal: true,
      trigshow: false,
      isModalOpen: false,
      userName: "",
      userEmail: "",
      userPhone: "",
      userImage: "",
      userProfile: {},
    };
  },
  computed: {
    ...mapGetters(["getCurrentUser", "getuserProfile"]),
  },

  mounted() {
    // During login

    // During page load
    // const storedProfile = localStorage.getItem("user");
    // if (storedProfile) {
    //   this.userProfile = JSON.parse(storedProfile);
    // }
    this.userProfile = this.getCurrentUser;

    this.getUser();
    window.addEventListener("scroll", this.handleScroll);

    const animationData = require("../../public/img/lottie/profile.json"); // Replace with your animation data file
    const options = {
      container: this.$refs.dotlottieplayer,
      animationData: animationData,
      loop: true,
      autoplay: true,
    };
    this.lottieInstance = lottie.loadAnimation(options);
    //console.log("me all", this.getCurrentUser.phone);
  },
  beforeUnmount() {
    // Destroy the Lottie animation instance to clean up resources
    if (this.lottieInstance) {
      this.lottieInstance.destroy();
    }
  },
  // mounted() {
  //   window.addEventListener("scroll", this.handleScroll);
  //   // window.addEventListener("scroll", this.handleScroll02);
  //   // window.addEventListener("scroll", this.handleScroll03);
  // },
  methods: {
    ...mapActions(["deleteUser"]),
    handleScroll() {
      const section = this.$refs.triggerSection;

      if (!section || this.triggered) return;

      const sectionRect = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (sectionRect.top < windowHeight && sectionRect.bottom > 0) {
        // Section is in view
        this.triggered = true; // Trigger only once
        this.trigshow = true;
      }
    },
    openModal() {
      this.isModalOpen = true;
      console.log("It is opened");
      gsap.to(
        this.$refs.modal,
        // { scale: 3, opacity: 0 },
        { duration: 0.9, scale: 0.2, opacity: 1 },
        { scale: 1, opacity: 1, duration: 0.9, ease: "power2.out" }
      );
    },
    closeModal() {
      console.log("This is closed function");
      this.isModalOpen = false;
    },
    deletenow() {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let deleteresult = await this.deleteUser();
          if (deleteresult.status === 200) {
            //toast.success(deleteresult.data.message);
            //this.onLoad();
            this.$swal({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
            });
            this.$router.push({ name: "Home" });
          }
        }
      });
    },
    getUser() {
      const user_data = this.getCurrentUser;

      // const user_data = this.userProfile;
      //const user_data = this.userProfile;
      console.log(user_data, "Again Me");

      this.userName = user_data.name;
      this.userEmail = user_data.email;
      this.userPhone = user_data.phone;
      this.userImage =
        "https://virtualrealitycreators.com/trackpal/" +
        user_data.profile_image;
      console.log(user_data.name, "zagar");
    },
  },
};
</script>

<style scoped>
/* .contact ul {
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  max-width: 400px;
  margin: 60px auto;
}
.contact li {
  list-style-type: none;
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  line-height: 1.5em;
} */
.contact-section .card {
  min-height: 265px;
}
.contact-section .my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 2.5rem !important;
}
.card-profile-image {
  overflow: hidden;
  margin-top: 30px;
  margin-bottom: 0px;
}
.card-profile-image img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin: auto;
  display: table;
}
.infoboxx {
  padding: 30px;
}
.userInfo {
  position: relative;
  margin-top: -15px;
}
.userInfo .lotiibox {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999;
}
.userInfo .section-title {
  margin-bottom: 0;
  text-align: left;
}
.infolabel {
  font-weight: 700;
  color: #000;
  text-align: right;
  font-family: "dosis";
}
.infodes {
  font-weight: 400;
  color: #aa1d88;
  font-size: 1rem;
}
.default-btn-two {
  margin-top: 0;
}
.page-title-content ul {
  margin: auto;
  margin-top: 12px;
  margin-bottom: 0;
  display: table;
  width: auto;
  padding: 0;
}

/* .modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
} */

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
button i.fa {
  margin-left: 12px;
}
</style>
